import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NotificationService } from './notify.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ErrorResponse } from '../models/jsonapi.model';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  STORAGE_PREFIX: string = environment.storage_prefix;
  API_AUTH_BASE: string = environment.auth_base_url;
  API_AUTH_CLIENT_ID: string = environment.auth_client_id;
  API_AUTH_CLIENT_SECRET: string = environment.auth_client_secret;
  API_AUTH_SCOPE: string = environment.auth_scope;
  API_BASE: string = environment.base_url;
  API_VERSION: string = environment.version;

  private translations = [];
  private access_token = '';
  private refresh_token = '';
  private auth = '';
  private user_id = '';

  constructor(
    private router: Router,
    private translate: TranslateService,
    private notification: NotificationService,
    public httpClient: HttpClient,
    public storageService: StorageService
    // public Md5: Md5,
    // public storage: Storage,
  ) {
    this.translate.get([
      'i18n.auth_error.0',
      'i18n.auth_error.400',
      'i18n.auth_error.401',
      'i18n.auth_error.404',
      'i18n.auth_error.412',
      'i18n.auth_error.422',
      'i18n.auth_error.429',
      'i18n.auth_error.500',
      'i18n.auth_error.505',
      'i18n.auth_error.default'
    ]).subscribe(t => { this.translations = t; });
  }

  public getJsonApiHeaders(): HttpHeaders {
    const header = new HttpHeaders({
      'Content-Type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json',
      Authorization: 'Bearer ' + this.getToken()
    });
    return header;
  }

  public getJsonApiFileHeaders(): HttpHeaders {
    const header = new HttpHeaders({
      'Content-Type': 'application/pdf',
      'Content-Disposition': 'attachment; filename=report.pdf',
      Accept: 'application/vnd.api+json',
      Authorization: 'Bearer ' + this.getToken()
    });
    return header;
  }

  userAuthenticated() {
    if (this.auth != '')
      return true;
    else
      return false;
  }

  loginWithCredentials(username: string, password: string) {
    // const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    // const body = 'email=' + username + '&password=' + password;
    const headers = new HttpHeaders().set('Content-Type', 'application/json')
    // const body = {
    //   email: username,
    //   password: password
    // };
    const body = {
      grant_type: 'password',
      client_id: this.API_AUTH_CLIENT_ID,
      client_secret: this.API_AUTH_CLIENT_SECRET,
      username: username,
      password: password,
      scope: this.API_AUTH_SCOPE
    };
    // const options = { headers: headers };
    const options = {};
    return new Promise(resolve => {
      this.httpClient.post(`${this.API_AUTH_BASE}/token`, body, options).subscribe(
        async (data: any) => {
          this.auth = 'auth';
          // if ((data) && (data.data) && (data.data.attributes) && (data.data.attributes.access_token)) {
          //   this.access_token = data.data.attributes.access_token;
          //   this.refresh_token = data.attributes.access_token;
          if ((data) && (data.access_token)) {
            this.access_token = data.access_token;
            this.refresh_token = data.refresh_token;
            this.storageService.set(this.STORAGE_PREFIX + 'access_token', this.access_token);
            this.storageService.set(this.STORAGE_PREFIX + 'refresh_token', this.refresh_token);

            await this.currentUser();
            resolve(true);
          }
          else {
            this.storageService.remove(this.STORAGE_PREFIX + 'access_token');
            this.storageService.remove(this.STORAGE_PREFIX + 'refresh_token');
            this.storageService.remove(this.STORAGE_PREFIX + 'user_id');
            this.storageService.remove(this.STORAGE_PREFIX + 'user')
            this.auth = '';
            resolve(false);
          }
        },
        (error: any) => {
          console.error('LOGIN', error);
          this.storageService.remove(this.STORAGE_PREFIX + 'access_token');
          this.storageService.remove(this.STORAGE_PREFIX + 'refresh_token');
          this.storageService.remove(this.STORAGE_PREFIX + 'user_id');
          this.storageService.remove(this.STORAGE_PREFIX + 'user')
          this.auth = '';
          resolve(false);
          this.handlerError(error);
        }
      );
    })
  }

  loginWithCode(code: string) {
    return new Promise(resolve => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      const options = { headers: headers };
      this.httpClient.post(`${this.API_AUTH_BASE}/${this.API_VERSION}/login`, {
        'badge': code
      }, options)
        .subscribe(
          (data: any) => {
            // console.log('LOGIN', data);
            this.auth = 'auth';
            this.access_token = data.access_token;
            // TODO Prevedere il salvataggio sullo store se richiesta la persistenza.
            resolve(true);
          },
          (error: any) => {
            // console.error('LOGIN', error); resolve(false);
            this.handlerError(error);
          }
        );
    });
  }

  refreshToken() {
    console.warn('TODO refreshToken()');
  }

  autoLogin() {
    return new Promise(resolve => {
      // console.log('autoLogin');
      this.access_token = '';
      this.auth = '';
      this.storageService.get(this.STORAGE_PREFIX + 'access_token').then(access_token => {
        // console.log('access_token', access_token);
        if (access_token != null && access_token != '') {
          this.auth = 'auth';
          this.access_token = access_token;
          resolve(true);

          // this.storageService.get(this.STORAGE_PREFIX + 'refresh_token').then(refresh_token =>{
          //   if (refresh_token != null && refresh_token != '') {
          //     this.refreshToken();
          //     this.auth = 'auth';
          //     resolve(true);
          //   }
          //   else {
          //     resolve(null);
          //   }
          // });
        }
        else
          resolve(null);
      });
    });
  }

  refreshLogin() {
    return new Promise(resolve => {
      // console.log('refreshLogin');
      this.storageService.get(this.STORAGE_PREFIX + 'access_token').then(access_token => {
        // console.log('access_token', access_token);
        if ((access_token != null) && (access_token != '')) {
          this.auth = 'auth';
          this.access_token = access_token;
          resolve(true);
        }
        else {
          resolve(null);
        }
      });
    });
  }

  logout() {
    return new Promise(resolve => {
      // console.warn('TODO');
      if (this.access_token != null && this.access_token != '') {
        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.access_token
        });
        const options = { headers: headers };
        this.httpClient.get(`${this.API_BASE}/${this.API_VERSION}/auth/logout`, options)
          .subscribe(
            (data: any) => {
              // console.log('LOGOUT 1', data);
              this.access_token = '';
              this.auth = '';
              this.storageService.remove(this.STORAGE_PREFIX + 'access_token');
              this.storageService.remove(this.STORAGE_PREFIX + 'refresh_token');
              this.storageService.remove(this.STORAGE_PREFIX + 'user_id');

              this.storageService.remove(this.STORAGE_PREFIX + 'user')
              resolve(true);
            },
            (error: any) => {
              // console.error('LOGOUT 2', error);
              this.access_token = '';
              this.auth = '';
              this.storageService.remove(this.STORAGE_PREFIX + 'access_token');
              this.storageService.remove(this.STORAGE_PREFIX + 'refresh_token');
              this.storageService.remove(this.STORAGE_PREFIX + 'user_id');

              this.storageService.remove(this.STORAGE_PREFIX + 'user')
              this.handlerError(error);
              resolve(false);
            }
          );
      }
      else {
        this.auth = '';
        this.storageService.remove(this.STORAGE_PREFIX + 'access_token');
        this.storageService.remove(this.STORAGE_PREFIX + 'refresh_token');
        this.storageService.remove(this.STORAGE_PREFIX + 'user_id');

        this.storageService.remove(this.STORAGE_PREFIX + 'user')
        resolve(true);
      }
    });
  }

  sendToken(token: string) {
    this.access_token = token;
  }

  getToken() {
    return this.access_token;
  }

  public handlerError(error: ErrorResponse) {
    // console.log(error);
    switch (error.status) {
      case 0:
        this.notification.toast(this.translations['i18n.auth_error.0'], 'danger');
        break;

      case 400:
      case 401:
        this.notification.toast(this.translations['i18n.auth_error.401'], 'danger');
        this.access_token = '';
        this.auth = '';
        // redirect
        const route = '';
        this.router.navigate([route], {});
        break;

      case 404:
        this.notification.toast(this.translations['i18n.auth_error.404'], 'danger');
        break;

      case 412: // eventualmente diversificare qui.
        this.notification.toast(this.translations['i18n.auth_error.412'], 'danger');
        break;

      case 422: // eventualmente diversificare qui.
        if (error.error && error.error) {
          const errorMessage: any = error.error;
          if ('string' == typeof errorMessage.error) {
            const message = errorMessage.error;
            this.notification.toast(this.translate.instant(message), 'danger');
          }
          else {
            if ('object' == typeof errorMessage.error) {
              if (errorMessage.error.email) {
                if ('string' == typeof errorMessage.error.email) {
                  const message = errorMessage.error.email;
                  this.notification.toast(this.translate.instant(message), 'danger');
                }
                if ('object' == typeof errorMessage.error.email) {
                  const message = errorMessage.error.email[0];
                  this.notification.toast(this.translate.instant(message), 'danger');
                }
              }
              else {
                this.notification.toast(this.translations['i18n.error.422'], 'danger');
              }
            }
            else {
              this.notification.toast(this.translations['i18n.error.422'], 'danger');
            }
          }
        }
        else {
          this.notification.toast(this.translations['i18n.error.422'], 'danger');
        }
        break;

      case 429:
        this.notification.toast(this.translations['i18n.auth_error.429'], 'danger');
        break;

      case 500:
        this.notification.toast(this.translations['i18n.auth_error.500'], 'danger');
        break;

      case 505:
        this.notification.toast(this.translations['i18n.auth_error.505'], 'danger');
        break;

      default:
        this.notification.toast(error.status.toString() + ' - ' + this.translations['i18n.auth_error.default'], 'danger');
        break;
    }
  }

  registerCredentials(username: string, password: string, language: string) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json')
    const body = {
      email: username,
      password: password,
      language: language
    };
    const options = { headers: headers };
    return this.httpClient.post(`${this.API_BASE}/register`, body, options);
  }

  currentUser() {
    return new Promise(resolve => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.access_token
      });
      const options = { headers: headers };

      this.httpClient.get(`${this.API_BASE}/${this.API_VERSION}/auth/current-user`, options).subscribe(
        (data: any) => {
          if ((data) && (data.data) && (data.data.id)) {
            this.user_id = data.data.id;
            this.storageService.set(this.STORAGE_PREFIX + 'user_id', this.user_id);
            resolve(true);
          }
          else {
            resolve(false);
          }
        },
        (error: any) => {
          // console.error('LOGIN', error);
          resolve(false);
          this.handlerError(error);
        }
      );
    })
  }
}
