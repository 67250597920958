import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

/**
 * Language key
 */
const LNG_KEY = 'SELECTED_LANGUAGE';

/**
 * Language service
 */
@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  /**
   * The current language
   */
  selected = '';

  /**
   * @ignore
   * 
   * @param translate 
   * @param storage 
   * @param plt 
   */
  constructor(
    private translate: TranslateService,
    private storage: Storage,
    private plt: Platform
  ) { }

  /**
   * Set initial language to the app
   */
  setInitialAppLanguage() {
    let language = 'en'; // this.translate.getBrowserLang();
    language = (this.isAvailableLanguage(language)) ? language : 'en';
    this.translate.setDefaultLang(language);

    // Preserve previous selected language.
    // this.storage.get(LNG_KEY).then(val => {
    //   if (val) {
    //     this.setLanguage(val);
    //     this.selected = val;
    //   }
    //   else {
    //     this.setLanguage(language);
    //     this.selected = language;
    //   }
    // });

    this.setLanguage(language);
    this.selected = language;
  }

  /**
   * Check if the language is available
   * 
   * @param lng 
   * The language to check
   * @returns 
   * Return TRUE if available
   */
  isAvailableLanguage(lng) {
    let isAvailableLanguage = false;
    switch (lng) {
      case 'en':
      case 'it':
        isAvailableLanguage = true;
        break;

      default:
        isAvailableLanguage = false;
    }
    return isAvailableLanguage;
  }

  /**
   * Retrieve the available languages
   * 
   * @returns 
   */
  getLanguages() {
    return [
      { text: 'Italiano', value: 'it', img: 'assets/imgs/it.png' },
      { text: 'English', value: 'en', img: 'assets/imgs/it.png' },
    ];
  }

  /**
   * Set a language as current
   * 
   * @param lng 
   */
  setLanguage(lng) {
    this.translate.use(lng);
    this.selected = lng;
    this.storage.set(LNG_KEY, lng);
  }

  /**
   * Get the current language
   * 
   * @returns 
   */
  async getLanguage() {
    return new Promise<string>(async (resolve, reject) => {
      try {
        const lng = await this.storage.get(LNG_KEY);
        resolve(lng);
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Return the translation of the string in the current language
   * 
   * @param sourceString 
   * @param params 
   * @returns 
   */
  async getTranslation(sourceString, params = null) {
    return new Promise<string>(async (resolve, reject) => {
      try {
        this.translate.get(sourceString, params).subscribe(
          outputString => {
            resolve(outputString);
          }
        )
      } catch (error) {
        reject(error);
      }
    });
  }
}
