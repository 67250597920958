import {Injectable} from '@angular/core';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';

@Injectable()
export class RecoveryService {
  STORAGE_PREFIX: string = environment.storage_prefix;
  pendingResult: string;

  constructor(
    private storageService: StorageService,
  ) {

  }

  saveStateRecovery(recovery: any): Promise<boolean> {
    return this.storageService.setObject(this.STORAGE_PREFIX + 'state_recovery', recovery);
    // return this.storageService.set(this.STORAGE_PREFIX + 'state_recovery', recovery);
  }

  getStateRecovery(): Promise<any> {
    return this.storageService.getObject(this.STORAGE_PREFIX + 'state_recovery');
    // return this.storageService.get(this.STORAGE_PREFIX + 'state_recovery');
  }

  removeStateRecovery(): Promise<any> {
    return this.storageService.remove(this.STORAGE_PREFIX + 'state_recovery');
  }

}