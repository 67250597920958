import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  public READ_NOTIFICATION = 'read_notification';

  constructor(
    public storage: Storage
  ) {
    // console.info('Your storage provider is working here !');
  }

  // set a key/value
  async set(key: string, value: any): Promise<any> {
    try {
      const result = await this.storage.set(key, value);
      // console.log('set string in storage: ' + result);
      return true;
    } catch (reason) {
      console.error(reason);
      return false;
    }
  }

  // to get a key/value pair
  async get(key: string): Promise<any> {
    try {
      const result = await this.storage.get(key);
      // console.log('storageGET: ' + key + ': ' + result);
      if (result != null) {
        return result;
      }
      return null;
    } catch (reason) {
      console.error(reason);
      return null;
    }
  }

  // set a key/value object
  async setObject(key: string, object: Object) {
    try {
      const result = await this.storage.set(key, JSON.stringify(object));
      // console.log('set Object in storage: ' + result);
      return true;
    } catch (reason) {
      console.error(reason);
      return false;
    }
  }

  // get a key/value object
  async getObject(key: string): Promise<any> {
    try {
      // console.log('key');
      const result = await this.storage.get(key);
      // console.log('result', result);
      if (result != null) {
        return JSON.parse(result);
      }
      return null;
    } catch (reason) {
      console.error(reason);
      return null;
    }
  }

  // remove a single key value:
  remove(key: string) {
    try {
      return this.storage.remove(key);
    } catch (reason) {
      console.error(reason);
      return null;
    }
  }

  // delete all data from your application:
  clear() {
    this.storage.clear();
  }
}