import { Injectable } from '@angular/core';
import { ErrorHandlerService } from './error-handler.service';
import { JsonApiService } from './jsonapi.service';

@Injectable()
export class RequestService {

  constructor(
    private errorHandler: ErrorHandlerService,
    private api: JsonApiService
  ) { }

  async getRequests(filters = []) {
    return new Promise<any>((resolve, reject) => {
      try {
        this.api.getCollection('requests', filters).subscribe(
          (res: any) => {
            const requests = res;
            resolve(requests);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  async getRequest(requestId: number) {
    return new Promise<any>((resolve, reject) => {
      try {
        this.api.getEntity('requests', requestId).subscribe(
          (res: any) => {
            const requests = res;
            resolve(requests);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  createRequest(data: any) {
    return new Promise((resolve, reject) => {
      try {
        this.api.postEntity('requests', data).subscribe(
          (res: any) => {
            const request = res;
            resolve(request);
          },
          (error: any) => {
            console.warn(error);
            if (422 !== error.status) {
              this.errorHandler.manage(error);
            }
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  duplicateRequest(requestId: number, data: any) {
    return new Promise((resolve, reject) => {
      try {
        this.api.postEntityCustomPath(data, 'requests/' + requestId + '/duplicate').subscribe(
          (res: any) => {
            const request = res;
            resolve(request);
          },
          (error: any) => {
            console.warn(error);
            if (422 !== error.status) {
              this.errorHandler.manage(error);
            }
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  async getRequestTopics(requestId: number) {
    return new Promise((resolve, reject) => {
      try {
        this.api.customGetRequest('requests/' + requestId + '/topics').subscribe(
          (res: any) => {
            const topics = res;
            resolve(topics);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  async skipRequestTopic(requestId: number) {
    return new Promise((resolve, reject) => {
      try {
        this.api.customGetRequest('requests/' + requestId + '/skip_topics').subscribe(
          (res: any) => {
            // console.log(res);
            const topics = res;
            resolve(topics);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  async setRequestTopic(requestId: number, data: any) {
    return new Promise((resolve, reject) => {
      try {
        this.api.postEntityCustomPath(data, 'requests/' + requestId + '/topics').subscribe(
          (res: any) => {
            const topics = res;
            resolve(topics);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  async getRequestPreliminaryPages(requestId: number) {
    return new Promise((resolve, reject) => {
      try {
        this.api.customGetRequest('requests/' + requestId + '/preliminary').subscribe(
          (res: any) => {
            const preliminaryPages = res;
            resolve(preliminaryPages);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  async setRequestSubmission(submissionId: number, data: any) {
    return new Promise((resolve, reject) => {
      try {
        this.api.updateEntity('request-submissions', submissionId, data).subscribe(
          // this.api.postEntityCustomPath(data, 'request-submissions/' + submissionId).subscribe(
          (res: any) => {
            const submission = res;
            resolve(submission);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  async setRequestPreliminaryPage(requestId: number) {
    return new Promise((resolve, reject) => {
      try {
        const data = {
          data: {
            type: 'requests',
            id: String(requestId),
            attributes: {}
          }
        };
        this.api.updateEntityCustomPath(data, 'requests/' + requestId + '/preliminary').subscribe(
          (res: any) => {
            const request = res;
            resolve(request);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  async getRequestPages(requestId: number) {
    return new Promise((resolve, reject) => {
      try {
        this.api.customGetRequest('requests/' + requestId + '/pages').subscribe(
          (res: any) => {
            const pages = res;
            resolve(pages);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  async getRequestPage(requestId: number, pageId: number) {
    return new Promise((resolve, reject) => {
      try {
        this.api.customGetRequest('requests/' + requestId + '/pages/' + pageId).subscribe(
          (res: any) => {
            const page = res;
            resolve(page);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  async setRequestPage(requestId: number, pageId: number) {
    return new Promise((resolve, reject) => {
      try {
        const data = {
          data: {
            type: 'requests',
            id: String(requestId),
            attributes: {}
          }
        };
        this.api.updateEntityCustomPath(data, 'requests/' + requestId + '/pages/' + pageId).subscribe(
          (res: any) => {
            if (res && res.data && res.data.attributes) {
              const page = res.data.attributes;
              resolve(page);
            }
            else {
              reject();
            }
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  async setRequestNotes(requestId: number, feedbackFinalNotes: string) {
    return new Promise((resolve, reject) => {
      try {
        const data = {
          data: {
            type: 'requests',
            id: String(requestId),
            attributes: {
              feedback_final_notes: feedbackFinalNotes
            }
          }
        };
        this.api.updateEntityCustomPath(data, 'requests/' + requestId + '/notes').subscribe(
          (res: any) => {
            if (res && res.data && res.data.attributes) {
              const page = res.data.attributes;
              resolve(page);
            }
            else {
              reject();
            }
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  async setRequestLocalization(requestId: number, dataAttributes: any) {
    return new Promise((resolve, reject) => {
      try {
        const data = {
          data: {
            type: 'requests',
            id: String(requestId),
            attributes: dataAttributes
          }
        };
        this.api.updateEntityCustomPath(data, 'requests/' + requestId + '/localization').subscribe(
          (res: any) => {
            if (res && res.data && res.data.attributes) {
              const page = res.data.attributes;
              resolve(page);
            }
            else {
              reject();
            }
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  async getRequestReport(requestId: number) {
    return new Promise<any>((resolve, reject) => {
      try {
        this.api.customGetFileRequest('requests/' + requestId + '/report').subscribe(
          (res: any) => {
            const requests = res;
            resolve(requests);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  async getRequestReportBase64(requestId: number) {
    return new Promise<any>((resolve, reject) => {
      try {
        this.api.customGetRequest('requests/' + requestId + '/report-base64').subscribe(
          (res: any) => {
            const requests = res;
            resolve(requests);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  async getFeedbackPages(requestId: number) {
    return new Promise((resolve, reject) => {
      try {
        this.api.customGetRequest('requests/' + requestId + '/feedbacks').subscribe(
          (res: any) => {
            const feedbacks = res;
            resolve(feedbacks);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  async getFeedbackPage(requestId: number, feedbackId: number) {
    return new Promise((resolve, reject) => {
      try {
        this.api.customGetRequest('requests/' + requestId + '/feedbacks/' + feedbackId).subscribe(
          (res: any) => {
            const feedback = res;
            resolve(feedback);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  async setFeedbackPage(requestId: number, feedbackId: number) {
    return new Promise((resolve, reject) => {
      try {
        const data = {
          data: {
            type: 'feedback-page',
            id: String(requestId),
            attributes: {}
          }
        };
        this.api.updateEntityCustomPath(data, 'requests/' + requestId + '/feedbacks/' + feedbackId).subscribe(
          (res: any) => {
            if (res && res.data && res.data.attributes) {
              const feedback = res.data.attributes;
              resolve(feedback);
            }
            else {
              reject();
            }
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  async setFeedbackNotes(requestId: number, feedbackNotes: string) {
    return new Promise((resolve, reject) => {
      try {
        const data = {
          data: {
            type: 'feedback-note',
            id: String(requestId),
            attributes: {
              feedback_submission_note: feedbackNotes
            }
          }
        };
        this.api.updateEntityCustomPath(data, 'requests/' + requestId + '/feedback-notes').subscribe(
          (res: any) => {
            if (res && res.data && res.data.attributes) {
              const page = res.data.attributes;
              resolve(page);
            }
            else {
              reject();
            }
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  async setFeedbackSubmission(submissionId: number, data: any) {
    return new Promise((resolve, reject) => {
      try {
        this.api.updateEntity('feedback-submissions', submissionId, data).subscribe(
          // this.api.postEntityCustomPath(data, 'feedback-submissions/' + submissionId).subscribe(
          (res: any) => {
            const submission = res;
            resolve(submission);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

}
