import { Injectable } from '@angular/core';
import { ErrorHandlerService } from './error-handler.service';
import { JsonApiService } from './jsonapi.service';
import { AuthService } from './auth.service';

@Injectable()
export class BoatService {

  constructor(
    private errorHandler: ErrorHandlerService,
    private authService: AuthService,
    private api: JsonApiService
  ) { }

  async getBoats() {
    return new Promise<any>((resolve, reject) => {
      try {
        this.api.getCollection('boats').subscribe(
          (res: any) => {
            const boats = res;
            resolve(boats);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }
  
  async getBoat(id: string) {
    return new Promise((resolve, reject) => {
      try {
        this.api.getEntity('boats', id).subscribe(
          (res: any) => {
            const boat = res;
            resolve(boat);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  createBoat(data: any) {
    return new Promise((resolve, reject) => {
      try {
        this.api.postEntity('boats', data).subscribe(
          (res: any) => {
            const boat = res;
            resolve(boat);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  updateBoat(id: number, data: any) {
    return new Promise((resolve, reject) => {
      try {
        // console.log('has_favorite_location', data);
        this.api.updateEntity('boats', id, data).subscribe(
          (res: any) => {
            const boat = res;
            resolve(boat);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  createBoatLocation(data: any) {
    return new Promise((resolve, reject) => {
      try {
        this.api.postEntityCustomPath(data, 'boat-location-create').subscribe(
          (res: any) => {
            const location = res;
            resolve(location);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  removeBoatLocation(data: any) {
    return new Promise((resolve, reject) => {
      try {
        this.api.postEntityCustomPath(data, 'boat-location-remove').subscribe(
          (res: any) => {
            const location = res;
            resolve(location);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  associateBoatEquipment(data: any) {
    return new Promise((resolve, reject) => {
      try {
        this.api.postEntityCustomPath(data, 'boat-equipment').subscribe(
          (res: any) => {
            const association = res;
            resolve(association);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  updateBoatEquipment(equipmentId: number, data: any) {
    return new Promise((resolve, reject) => {
      try {
        this.api.updateEntityCustomPath(data, 'boat-equipment/' + equipmentId).subscribe(
          (res: any) => {
            // console.log('res', res);
            const association = res;
            resolve(association);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  removeBoatEquipment(data: any) {
    return new Promise((resolve, reject) => {
      try {
        this.api.postEntityCustomPath(data, 'boat-equipment-remove').subscribe(
          (res: any) => {
            const association = res;
            resolve(association);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  createBoatManagementRequest(data: any) {
    return new Promise((resolve, reject) => {
      try {
        this.api.postEntityCustomPath(data, 'boat-management-requests').subscribe(
          (res: any) => {
            const managementRequest = res;
            resolve(managementRequest);
          },
          (error: any) => {
            console.warn(error);
            // TODO Passare la stringa dell'errore.
            // error.status = 412;
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  getBoatManagementRequests() {
    return new Promise((resolve, reject) => {
      try {
        this.api.customGetRequest('boat-management-requests').subscribe(
          (res: any) => {
            const managementRequest = res;
            resolve(managementRequest);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  getBoatManagementRequest(id: any) {
    return new Promise((resolve, reject) => {
      try {
        this.api.customGetRequest('boat-management-requests/' + id).subscribe(
          (res: any) => {
            const managementRequest = res;
            resolve(managementRequest);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  updateBoatManagementRequest(id: number, data: any) {
    return new Promise((resolve, reject) => {
      try {
        this.api.updateEntity('boat-management-requests', id, data).subscribe(
          (res: any) => {
            const boat = res;
            resolve(boat);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }
}
