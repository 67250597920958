import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-assistance-modal',
  templateUrl: './assistance-modal.page.html',
  styleUrls: ['./assistance-modal.page.scss'],
})
export class AssistanceModalPage implements OnInit {
  public title: string;
  public content: string;
  public close: string;

  constructor(
    private navParams: NavParams,
    public modalController: ModalController
  ) {
    this.title = this.navParams.get('title');
    this.content = this.navParams.get('content');
    this.close = this.navParams.get('close');
  }

  ngOnInit() {
  }

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }}
