import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-media-modal',
  templateUrl: './media-modal.page.html',
  styleUrls: ['./media-modal.page.scss'],
})
export class MediaModalPage implements OnInit {
  public title: string;
  public format: string;
  public base64String: string;
  public url: string;
  public canDelete: boolean;

  public imageFormats = environment.imageFormats;
  public audioFormats = environment.audioFormats;
  public videoFormats = environment.videoFormats;

  constructor(
    private platform: Platform,
    private navParams: NavParams,
    public modalController: ModalController
  ) {
    this.title = this.navParams.get('title');
    this.format = this.navParams.get('format');
    this.base64String = this.navParams.get('base64String');
    this.url = this.navParams.get('url');
    this.canDelete = (this.navParams.get('canDelete'));
    this.canDelete = (this.canDelete) ? this.canDelete : false;
  }

  ngOnInit() {
    // console.log('MediaModalPage', this.title, this.format, this.url);
    if (!this.format && this.url) {
      this.format = this.getFormatFromUrl(this.url);
      // console.log('format missing, now', this.format);
    }
  }

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true,
      'deleted': false
    });
  }

  delete() {
    this.modalController.dismiss({
      'dismissed': true,
      'deleted': true
    });
  }

  getFormatFromUrl(url: string) {
    let format = 'unknown';
    const extension = url.split('.').pop().toLowerCase();
    switch (extension) {
      case 'wav':
        format = 'audio/x-wav';
        break;
      case 'mp3':
        format = 'audio/mpeg';
        break;
      case 'ogg':
        format = 'audio/ogg';
        break;
      case 'jpeg':
      case 'jpg':
        format = 'image/jpg';
        break;
      case 'png':
        format = 'image/png';
        break;
      case 'gif':
        format = 'image/gif';
        break;
      case 'svg':
        format = 'image/svg+xml';
        break;
      case 'webp':
        format = 'image/webp';
        break;
      case 'pdf':
        format = 'application/pdf';
        break;
      case 'mov':
        if (this.platform.is('ios')) {
          format = 'image/mov';
        }
        else {
          format = 'video/avi';
        }
        break;
      case 'mp4':
        format = 'video/mp4';
        break;
      case 'avi':
        format = 'video/avi';
        break;
      case 'ogv':
        format = 'video/ogg';
        break;
      case 'wmv':
        format = 'video/x-ms-wmv';
        break;
      case 'qt':
        format = 'video/mp4';
        break;
    }
    return format;
  }
}
