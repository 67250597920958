import { Animation, AnimationController } from '@ionic/angular';

/**
 * Page enter animation
 * 
 * @param baseEl 
 * @param opts 
 * @returns 
 */
export const enterAnimation = (baseEl: HTMLElement, opts?: any): Animation => {
  /**
   * The duration
   */
  const DURATION = 300;

  // console.log('enterAnimation baseEl', baseEl);
  // console.log('enterAnimation opts', opts);

  /**
   * The controller
   */
  const animationController = new AnimationController();

  if ('forward' === opts.direction) {
    return animationController.create()
    .addElement(opts.enteringEl)
    .duration(DURATION)
    .easing('ease-in')
    .fromTo('opacity', 0, 1);
  }
  else {
    const rootAnimation = animationController.create()
    .addElement(opts.enteringEl)
    .duration(DURATION)
    .easing('ease-in')
    .fromTo('opacity', 0, 1);

    const leavingAnimation = animationController.create()
    .addElement(opts.leavingEl)
    .duration(DURATION)
    .easing('ease-out')
    .fromTo('opacity', 1, 0);

    return animationController.create()
    .addAnimation([rootAnimation, leavingAnimation])
  }
}