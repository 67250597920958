import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  position: "top" | "bottom" | "middle";
  positionAbsolute: boolean;
  cssClass: string;

  constructor(private toastController: ToastController) {
    this.position = 'bottom';
    this.positionAbsolute = false;
    this.cssClass = 'ca-toast ca-toast--bottom';
  }

  public toast(message: string, type = 'success', duration = 2000, buttons = []) {
    let color = 'light',
      cssClass = this.cssClass,
      position = this.position;

    if (this.positionAbsolute) {
      switch (this.position) {
        case 'top':
          cssClass = this.cssClass + ' ' + 'ca-toast--top-absolute';
          break;
        case 'bottom':
          cssClass = this.cssClass + ' ' + 'ca-toast--bottom-absolute';
          break;
        case 'middle':
          cssClass = this.cssClass + ' ' + 'ca-toast--middle-absolute';
          break;
      }
    }

    switch (type) {
      case 'success':
        color = 'success',
          cssClass = cssClass + ' ' + 'ca-toast--success',
          position = position,
          buttons = buttons
        break;

      case 'danger':
        color = 'danger',
          cssClass = cssClass + ' ' + 'ca-toast--error',
          position = position,
          buttons = buttons
        break;

      default:
        color = 'light',
          cssClass = cssClass,
          position = position,
          buttons = buttons
    }

    this.toastController.create({
      message: message,
      duration: duration,
      color: color,
      position: position,
      cssClass: cssClass,
      buttons: buttons,
    }).then(toast => {
      toast.present();
    });
  }

  public setPositionAbsolute(flag: boolean) {
    this.positionAbsolute = flag;
  }

}