import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from './notify.service';
import { ErrorResponse } from '../models/jsonapi.model';
import { AuthService } from './auth.service';
import { take } from 'rxjs/operators';

@Injectable()
export class ErrorHandlerService {
  private translations = [];

  constructor(
    private router: Router,
    private translate: TranslateService,
    private notification: NotificationService,
    private auth: AuthService
  ) {
    this.translate.get([
      'i18n.error.0',
      'i18n.error.401',
      'i18n.error.404',
      'i18n.error.412',
      'i18n.error.422',
      'i18n.error.429',
      'i18n.error.500',
      'i18n.error.505',
      'i18n.error.default'
    ]).pipe(
      take(1)
    ).subscribe(t => { this.translations = t; });
  }

  public manage(error: ErrorResponse) {
    switch (error.status) {
      case 0:
        this.notification.toast(this.translations['i18n.error.0'], 'danger');
        break;
        
      case 401:
        this.notification.toast(this.translations['i18n.error.401'], 'danger');
        this.auth.logout();
        break;

      case 404:
        this.notification.toast(this.translations['i18n.error.404'], 'danger');
        break;

      case 412: // eventualmente diversificare qui.
        this.notification.toast(this.translations['i18n.error.412'], 'danger');
        break;

      case 422: // eventualmente diversificare qui.
        if (error.error && error.error) {
          const errorMessage: any = error.error;
          if ('string' == typeof errorMessage.error) {
            const message = errorMessage.error;
            this.notification.toast(this.translate.instant(message), 'danger');
          }
          else {
            if ('object' == typeof errorMessage.error) {
              if (errorMessage.error.email) {
                if ('string' == typeof errorMessage.error.email) {
                  const message = errorMessage.error.email;
                  this.notification.toast(this.translate.instant(message), 'danger');
                }
                if ('object' == typeof errorMessage.error.email) {
                  const message = errorMessage.error.email[0];
                  this.notification.toast(this.translate.instant(message), 'danger');
                }
              }
              else {
                this.notification.toast(this.translations['i18n.error.422'], 'danger');
              }
            }
            else {
              this.notification.toast(this.translations['i18n.error.422'], 'danger');
            }
          }
        }
        else {
          this.notification.toast(this.translations['i18n.error.422'], 'danger');
        }
        break;

      case 429:
        this.notification.toast(this.translations['i18n.error.429'], 'danger');
        break;
        
      case 500:
        this.notification.toast(this.translations['i18n.error.500'], 'danger');
        break;

      case 505:
        this.notification.toast(this.translations['i18n.error.505'], 'danger');
        break;

      default:
        this.notification.toast(error.status.toString() + ' - ' + this.translations['i18n.error.default'], 'danger');
        break;
    }
  }
}
