// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/**
 * Default environment
 */
export const environment = {
  production: false,
  storage_prefix: "ambiente_stage_",
  auth_base_url: "https://ambiente-spa.staging.netseven.it/oauth",
  auth_client_id: "2",
  auth_client_secret: "dVGIRg3dvEUWeMnUKuyH7Xrcy7Y9dw4PS2cAbN5q",
  auth_scope: "",
  base_url: "https://ambiente-spa.staging.netseven.it/api",
  domain: "https://ambiente-spa.staging.netseven.it",
  storage_url: "https://ambiente-spa.staging.netseven.it/storage",
  version: "v1",
  imageFormats: [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/gif',
    'image/svg+xml',
    'image/webp',
    'image/mov'
  ],
  audioFormats: [
    'audio/x-wav',
    'audio/mpeg',
    'audio/ogg'
  ],
  videoFormats: [
    'video/mov',
    'video/x-quicktime',
    'video/avi',
    'video/msvideo',
    'video/mp4',
    'video/ogg',
    'video/x-ms-wmv'
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
