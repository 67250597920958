import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { LanguageService } from '../services/language.service';

/**
 * Auth guard
 */
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  /**
   * @ignore
   * 
   * @param authService 
   * @param languageService 
   * @param router 
   */
  constructor(
    public authService: AuthService,
    public languageService: LanguageService,
    public router: Router
  ) {}

  /**
   * Can activate
   * 
   * @param next 
   * @param state 
   * @returns 
   */
  canActivate(
    next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if(!this.authService.userAuthenticated()){
        return this.authService.autoLogin().then(async res => {
          if (res != null && res == true){
            let lang = await this.languageService.getLanguage();
            return true;
          }
          else {
            this.authService.logout();
            this.router.navigate(['']);
            return false;
          }
        });
      }
      else
        return true;
  }
}