import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';
import { OptionService } from './option.service';
import { RequestService } from './request.service';
import { UserService } from './user.service';
import { LanguageService } from './language.service';
import { BoatService } from './boat.service';
import { CompanyService } from './company.service';
import { take } from 'rxjs/operators';
import { DocumentService } from './document.service';
import { findIndex, orderBy, uniqBy } from 'lodash-es';
import { TranslateService } from '@ngx-translate/core';

export interface Step {
  name: string,
  complete: boolean
}

export interface Completeness {
  completedSteps: number,
  totalSteps: number,
  steps: Step[]
}

export interface User {
  id: string,
  email: string,
  password: string,
  terms: boolean,
  language?: string,
  customerType: string,
  customerTypeId: number,
  name: string,
  lastname: string,
  mobileNumber: {
    prefix: string,
    number: string
  },
  otherNumber: {
    prefix: string,
    number: string
  },
  otherEmail: string,
  billing: string,
  billingName: string,
  billingLastname: string,
  useRegistry: string,
  fiscalCode: string,
  billingCountry: string,
  billingZip: string,
  billingAddress: string,
  companyId: number,
  company: any,
  boats: Boat[],
  steps?: boolean[]
}

export interface Location {
  id: string,
  name: string,
  address: string,
  city: string,
  country: string
}

export interface Boat {
  id: string,
  name: string,
  model: string,
  dockyard: string,
  hull_number: string,
  image?: string,
  full_access: boolean,
  has_equipment?: boolean,
  equipments: any[],
  has_locations?: boolean,
  has_favorite_location?: number,
  locations: any[],
  documents: any[],
  thumbs?: string[],
  completed?: boolean,
  steps?: boolean[]
}

export interface Company {
  id: string,
  company_business_name: string,
}

@Injectable({
  providedIn: 'root'
})
export class DataService {
  STORAGE_PREFIX: string = environment.storage_prefix;
  private locationsData: Location[];
  private boatsData: Boat[];
  private customerTypes: any[];
  private subjectTypes: any[];
  private companies: any[];
  private locations: any[];
  private profileCompletenessData: Completeness;
  private requestTypesData: any[];
  private requestStatusesData: any[];
  private requestsData: any[];

  public user = new BehaviorSubject({
    id: null,
    email: null,
    password: null,
    terms: false,
    customerType: null,
    customerTypeId: null,
    name: null,
    lastname: null,
    mobileNumber: {
      prefix: null,
      number: null
    },
    otherNumber: {
      prefix: null,
      number: null
    },
    otherEmail: null,
    billing: null,
    billingName: null,
    billingLastname: null,
    useRegistry: null,
    billingCountry: null,
    billingZip: null,
    billingAddress: null,
    fiscalCode: null,
    companyId: null,
    company: null,
    boats: [],
    steps: []
  });

  public profileCompleteness = new BehaviorSubject({
    completedSteps: 0,
    totalSteps: 3,
    steps: [
      {
        name: 'credentials',
        complete: false
      },
      {
        name: 'registry',
        complete: false
      },
      {
        name: 'invoicing',
        complete: false
      }
    ]
  });

  public boats = new BehaviorSubject([]);
  public locations$ = new BehaviorSubject([]);
  public flows$ = new BehaviorSubject([]);

  public requestTypes = new BehaviorSubject([]);
  public requestStatuses = new BehaviorSubject([]);

  public requests = new BehaviorSubject([]);

  public equipmentTypes = new BehaviorSubject([]);
  public equipments = new BehaviorSubject([]);

  constructor(
    public storageService: StorageService,
    private optionService: OptionService,
    private requestService: RequestService,
    private userService: UserService,
    private boatService: BoatService,
    private companyService: CompanyService,
    private documentService: DocumentService,
    private languageService: LanguageService,
    private translate: TranslateService
  ) {
    this.customerTypes = [];
    this.subjectTypes = [];
    this.requestTypesData = [];
    this.requestStatusesData = [];
    this.profileCompletenessData = {
      completedSteps: 0,
      totalSteps: 3,
      steps: [
        {
          name: 'credentials',
          complete: false
        },
        {
          name: 'registry',
          complete: false
        },
        {
          name: 'invoicing',
          complete: false
        }
      ]
    }
  }

  async waitFor(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async asyncForEach(source, callback) {
    if (source instanceof Array) {
      for (let index = 0; index < source.length; index++) {
        await callback(source[index], index, source);
      }
    }
    else {
      for (var key in source) {
        if (source.hasOwnProperty(key)) {
          await callback(source[key], key, source);
        }
      }
    }
  }

  public loadOptions(unrestrictedOnly = false) {
    return new Promise(async (resolve, reject) => {
      try {
        const language = await this.languageService.getLanguage();
        let customerTypes = [];
        // const customerTypesData = await this.optionService.getCustomerTypes();
        // await this.asyncForEach(customerTypesData.data, element => {
        //   customerTypes.push({
        //     id: parseInt(element.id),
        //     // value: element.attributes.value,
        //     value: parseInt(element.id),
        //     name: element.attributes.name[language]
        //   });
        // });
        this.customerTypes = customerTypes;

        let subjectTypes = [];
        // subjectTypes.push({
        //   id: 1,
        //   value: 'personal',
        //   name: 'personal'
        // });
        // subjectTypes.push({
        //   id: 2,
        //   value: 'company',
        //   name: 'company'
        // });
        this.subjectTypes = subjectTypes;

        // Apparentemente non più utilizzate sulla mobile app
        // let companies = [];
        // if (!unrestrictedOnly) {
        //   const companiesData = await this.optionService.getCompanies();
        //   await this.asyncForEach(companiesData.data, element => {
        //     companies.push({
        //       id: parseInt( element.id ),
        //       // value: element.attributes.value,
        //       value: parseInt( element.id ),
        //       name: element.attributes.company_business_name
        //     });
        //   });
        // }
        // this.companies = companies;

        // Apparentemente non più utilizzate sulla mobile app
        // let locations = [];
        // if (!unrestrictedOnly) {
        //   const locationsData = await this.optionService.getLocations();
        //   await this.asyncForEach(locationsData.data, element => {
        //     console.log('element', element);
        //     locations.push({
        //       id: parseInt( element.id ),
        //       // value: element.attributes.value,
        //       value: parseInt( element.id ),
        //       name: element.attributes.addresses[0].organization
        //     });
        //   });
        // }
        // this.locations = locations;

        let requestTypes = [];
        if (!unrestrictedOnly) {
          const requestTypesData = await this.optionService.getRequestTypes();
          await this.asyncForEach(requestTypesData.data, element => {
            requestTypes.push({
              id: parseInt(element.id),
              value: element.attributes.value,
              // value: parseInt( element.id ),
              name: element.attributes.name
            });
          });
          requestTypes.push({
            id: 0,
            value: 'copia',
            name: this.translate.instant('i18n.page.home.copy', {})
          });
        }
        else {
          requestTypes.push({
            id: 1,
            value: 'rifiuti',
            name: this.translate.instant('i18n.page.home.waste', {})
          });
          requestTypes.push({
            id: 2,
            value: 'acque',
            name: this.translate.instant('i18n.page.home.waters', {})
          });
          requestTypes.push({
            id: 3,
            value: 'terreni',
            name: this.translate.instant('i18n.page.home.soils_and_sediments', {})
          });
        }
        this.requestTypesData = requestTypes.sort((a, b) => b.value - a.value);
        this.requestTypes.next(requestTypes.sort((a, b) => b.value - a.value));

        let requestStatuses = [];
        if (!unrestrictedOnly) {
          const requestStatusesData = await this.optionService.getRequestStatuses();
          await this.asyncForEach(requestStatusesData.data, element => {
            requestStatuses.push({
              id: parseInt(element.id),
              value: element.attributes.value,
              order_column: element.attributes.order_column,
              // value: parseInt( element.id ),
              name: element.attributes.name
            });
          });
        }
        this.requestStatusesData = requestStatuses.sort((a, b) => a.order_column - b.order_column);
        this.requestStatuses.next(requestStatuses.sort((a, b) => a.order_column - b.order_column));
        resolve(true);
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    })
  }

  public getRequestType(id: string) {
    return new Promise<any>(async resolve => {
      this.optionService.getRequestType(id).then((res: any) => {
        const requestTypeDataAttributes = res.data.attributes;
        const requestType = {
          ...requestTypeDataAttributes,
          id: res.data.id
        }
        resolve(requestType);
      }).catch((error: any) => {
        console.error(error);
        resolve(null);
      });
    })
  }

  public getCustomerTypes() {
    return new Promise<any[]>(async (resolve, reject) => {
      try {
        resolve(this.customerTypes);
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    })
  }

  public getCompanies() {
    return new Promise<any[]>(async (resolve, reject) => {
      try {
        resolve(this.companies);
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    })
  }

  public createCompany(company) {
    return new Promise(async resolve => {
      const companyData = {
        data: {
          type: 'companies',
          attributes: {}
        }
      };
      if (company.company_business_name) {
        companyData.data.attributes['company_business_name'] = company.company_business_name;
      };
      if (company.company_email) {
        companyData.data.attributes['company_email'] = company.company_email;
      };
      if (company.company_phone_prefix && company.company_phone) {
        companyData.data.attributes['company_phone'] = ((company.company_phone_prefix) ? ('+' + company.company_phone_prefix) : '') + '@' + company.company_phone;
      };
      if (company.company_fiscal_code) {
        companyData.data.attributes['company_fiscal_code'] = company.company_fiscal_code;
      };
      if (company.referent_email) {
        companyData.data.attributes['referent_email'] = company.referent_email;
      };
      if (company.referent_phone_prefix && company.referent_phone) {
        companyData.data.attributes['referent_phone'] = ((company.referent_phone_prefix) ? ('+' + company.referent_phone_prefix) : '') + '@' + company.referent_phone;
      };
      if (company.referent_name) {
        companyData.data.attributes['referent_name'] = company.referent_name;
      };
      if (company.referent_surname) {
        companyData.data.attributes['referent_surname'] = company.referent_surname;
      };
      this.companyService.createCompany(companyData).then((res: any) => {
        // console.log(res);
        resolve(res.data);
      }).catch((error: any) => {
        console.error(error);
        resolve(false);
      });
    })
  }

  public updateCompany(companyId: number, company: any) {
    return new Promise(async resolve => {
      const companyData = {
        data: {
          type: 'companies',
          id: String(companyId),
          attributes: {}
        }
      };
      if (company.company_business_name) {
        companyData.data.attributes['company_business_name'] = company.company_business_name;
      };
      if (company.company_email) {
        companyData.data.attributes['company_email'] = company.company_email;
      };
      if (company.company_phone_prefix && company.company_phone) {
        companyData.data.attributes['company_phone'] = ((company.company_phone_prefix) ? ('+' + company.company_phone_prefix) : '') + '@' + company.company_phone;
      };
      if (company.company_fiscal_code) {
        companyData.data.attributes['company_fiscal_code'] = company.company_fiscal_code;
      };
      if (company.referent_email) {
        companyData.data.attributes['referent_email'] = company.referent_email;
      };
      if (company.referent_phone_prefix && company.referent_phone) {
        companyData.data.attributes['referent_phone'] = ((company.referent_phone_prefix) ? ('+' + company.referent_phone_prefix) : '') + '@' + company.referent_phone;
      };
      if (company.referent_name) {
        companyData.data.attributes['referent_name'] = company.referent_name;
      };
      if (company.referent_surname) {
        companyData.data.attributes['referent_surname'] = company.referent_surname;
      };
      this.companyService.updateCompany(companyId, companyData).then((res: any) => {
        // console.log('updateCompany:', res);
        resolve(res.data);
      }).catch((error: any) => {
        console.error(error);
        resolve(false);
      });
    })
  }

  public getSubjectTypes() {
    return new Promise<any[]>(async (resolve, reject) => {
      try {
        resolve(this.subjectTypes);
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    })
  }

  public getLocations() {
    return new Promise<any[]>(async (resolve, reject) => {
      try {
        resolve(this.locations);
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    })
  }

  // public getLocation(locationId: number) {
  //   return new Promise<any>(async (resolve, reject) => {
  //     try {
  //       this.optionService.getLocation(String(locationId)).then((res: any) => {
  //         // console.log(res);
  //         const locationDataAttributes = res.data.attributes;
  //         const location = {
  //           id: res.data.id,
  //           locationType: ((locationDataAttributes.location_type) ? (locationDataAttributes.location_type) : ''),
  //           addresses: ((locationDataAttributes.addresses) ? (locationDataAttributes.addresses) : [])
  //         }
  //         resolve(location);
  //       });
  //     } catch (error) {
  //       console.error('reject');
  //       reject(error);
  //     }
  //   })
  // }

  public searchLocations(text: string = '_') {
    return new Promise(async (resolve, reject) => {
      try {
        const language = await this.languageService.getLanguage();
        const locations = [];
        const locationsData = this.locationsData;
        // const orderedEquipmentTypesData = orderBy(equipmentTypesData, ['equipment_type', 'brand'], ['asc']);
        await this.asyncForEach(locationsData, element => {
          // console.log('element', element);
          if (
            (0 === text.length) ||
            ('_' === text) ||
            (element.name.toLowerCase().includes(text.toLowerCase())) ||
            (element.city.toLowerCase().includes(text.toLowerCase())) ||
            (element.country.toLowerCase().includes(text.toLowerCase()))
          ) {
            locations.push({
              id: element.id,
              name: element.name,
              address: element.address,
              city: element.city,
              country: element.country,
            });
            this.locations$.next(locations);
          }
        });
        this.locations$.next(locations);
        resolve(true);
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    })
  }

  public searchFlows(flowTypeId: string, locationId: string) {
    return new Promise<any[]>(async (resolve, reject) => {
      try {
        // const language = await this.languageService.getLanguage();
        let flows = [];
        const filter = [
          {
            name: "flow_type_id",
            value: flowTypeId
          },
          {
            name: "is_visible",
            value: 0
          }
        ];

        const flowsData = await this.optionService.getFlows(filter);
        await this.asyncForEach(flowsData, element => {
          // console.log('element', element);
          flows.push({
            id: element.id,
            name: element.attributes.name,
            description: element.attributes.description,
            flow_type: element.attributes.flow_type,
          });
        });
        this.flows$.next(flows);
        resolve(flows);
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    })
  }

  public searchEquipmentTypes(text: string = '_') {
    return new Promise(async (resolve, reject) => {
      try {
        const language = await this.languageService.getLanguage();
        let equipmentTypes = [];
        const equipmentTypesData = await this.optionService.searchEquipmentTypes(text, language);
        const orderedEquipmentTypesData = orderBy(equipmentTypesData, ['equipment_type', 'brand'], ['asc']);
        await this.asyncForEach(orderedEquipmentTypesData, element => {
          // console.log('element', element);
          equipmentTypes.push({
            brand: element.brand,
            brand_id: element.brand_id,
            equipment_type: element.equipment_type,
            equipment_type_id: element.equipment_type_id,
            from_tag: element.from_tag,
          });
          this.equipmentTypes.next(equipmentTypes);
        });
        this.equipmentTypes.next(equipmentTypes);
        resolve(true);
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    })
  }

  public searchEquipments(equipmentTypeId: number, brandId: number) {
    return new Promise<any[]>(async (resolve, reject) => {
      try {
        // const language = await this.languageService.getLanguage();
        let equipments = [];
        const equipmentsData = await this.optionService.searchEquipments(String(equipmentTypeId), String(brandId));
        await this.asyncForEach(equipmentsData, element => {
          // console.log('element', element);
          equipments.push({
            id: element.id,
            brand: element.brand,
            brand_id: element.brand_id,
            equipment_type: element.equipment_type,
            equipment_type_id: element.equipment_type_id,
            model: element.model,
          });
        });
        this.equipments.next(equipments);
        resolve(equipments);
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    })
  }

  public getEquipment(id: string) {
    return new Promise<any>(async resolve => {
      this.optionService.getEquipment(id).then((res: any) => {
        const equipmentDataAttributes = res.data.attributes;
        const equipment = { ...equipmentDataAttributes, id: res.data.id };
        resolve(equipment);
      }).catch((error: any) => {
        console.error(error);
        resolve(null);
      });
    })
  }

  public recoverPassword(email: string) {
    return new Promise(async (resolve, reject) => {
      try {
        this.userService.recoverPassword(email).then((res: any) => {
          resolve(res);
        }).catch((error) => {
          console.error('error', error);
          resolve(false);
        });
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  public checkCodePassword(email: string, code: string) {
    return new Promise(async (resolve, reject) => {
      try {
        this.userService.checkCodePassword(email, code).then((res: any) => {
          resolve(res);
        }).catch((error) => {
          console.error('error', error);
          resolve(false);
        });
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  public updatePassword(email: string, code: string, password: string) {
    return new Promise(async (resolve, reject) => {
      try {
        this.userService.updatePassword(email, code, password).then((res: any) => {
          resolve(res);
        }).catch((error) => {
          console.error('error', error);
          resolve(false);
        });
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  public recoverEmail(email: string) {
    return new Promise(async (resolve, reject) => {
      try {
        this.userService.recoverEmail(email).then((res: any) => {
          resolve(res);
        }).catch((error) => {
          console.error('error', error);
          resolve(false);
        });
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  public checkCodeEmail(email: string, code: string) {
    return new Promise(async (resolve, reject) => {
      try {
        this.userService.checkCodeEmail(email, code).then((res: any) => {
          resolve(res);
        }).catch((error) => {
          console.error('error', error);
          resolve(false);
        });
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  public updateEmail(email: string, code: string, newEmail: string) {
    return new Promise(async (resolve, reject) => {
      try {
        this.userService.updateEmail(email, code, newEmail).then((res: any) => {
          resolve(res);
        }).catch((error) => {
          console.error('error', error);
          resolve(false);
        });
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  public loadUser(currentLanguage: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const userId = await this.storageService.get(this.STORAGE_PREFIX + 'user_id');
        if (userId) {
          this.userService.getUser(userId).then((res: any) => {
            const userDataAttributes = res.data.attributes;
            console.log('userDataAttributes.customer_type', userDataAttributes.customer_type);
            const user = {
              id: res.data.id,
              email: userDataAttributes.email,
              password: null,
              terms: true,
              customerType: (userDataAttributes.customer_type) ? userDataAttributes.customer_type : 'Operatore sul campo',
              customerTypeId: parseInt(userDataAttributes.customer_type_id),
              language: userDataAttributes.language,
              name: userDataAttributes.name,
              lastname: userDataAttributes.surname,
              mobileNumber: {
                prefix: ((userDataAttributes.phone) ? userDataAttributes.phone.split('@')[0].replace('+', '') : null),
                number: ((userDataAttributes.phone) ? userDataAttributes.phone.split('@')[1] : null)
              },
              otherNumber: {
                prefix: ((userDataAttributes.phone_secondary) ? userDataAttributes.phone_secondary.split('@')[0].replace('+', '') : null),
                number: ((userDataAttributes.phone_secondary) ? userDataAttributes.phone_secondary.split('@')[1] : null)
              },
              otherEmail: userDataAttributes.email_secondary,
              billing: userDataAttributes.billing,
              billingName: userDataAttributes.billing_name,
              billingLastname: userDataAttributes.billing_surname,
              useRegistry: null,
              fiscalCode: userDataAttributes.fiscal_code,
              billingCountry: userDataAttributes.billing_country,
              billingZip: userDataAttributes.billing_cap,
              billingAddress: userDataAttributes.billing_address,
              companyId: ((userDataAttributes.company) ? parseInt(userDataAttributes.company.id) : null),
              company: ((userDataAttributes.company) ? (userDataAttributes.company) : null),
              boats: ((userDataAttributes.boats) ? (userDataAttributes.boats) : []),
              steps: []
            }
            if (currentLanguage != userDataAttributes.language) {
              user.language = currentLanguage;
              this.setUser(user);
              this.updateUser(userId, { language: currentLanguage });
              this.loadOptions();
            }
            this.storageService.setObject(this.STORAGE_PREFIX + 'user', user);
            // console.log('user', user);
            this.user.next(user);
            resolve(true);
          }).catch((error) => {
            console.error('error', error);
            resolve(false);
          });
        }
        else {
          console.log('else');
          resolve(false);
        }
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    })
  }

  public getUser() {
    return new Promise(async resolve => {
      const res = {
        id: null,
        email: null,
        password: null,
        terms: false,
        customerType: null,
        customerTypeId: null,
        name: null,
        lastname: null,
        mobileNumber: {
          prefix: null,
          number: null
        },
        otherNumber: {
          prefix: null,
          number: null
        },
        otherEmail: null,
        billing: null,
        useRegistry: null,
        fiscalCode: null,
        sync: false,
        steps: {
          1: false,
          2: false,
          3: false
        }
      }

      this.storageService.getObject(this.STORAGE_PREFIX + 'user').then(user => {
        // console.log('getUser user', user);
        if (user) {
          this.user.next(user);
          resolve(true);
        }
        else {
          resolve(null);
        }
      });
    })
  }

  public resetUser() {
    const user = {
      id: null,
      email: null,
      password: null,
      terms: false,
      customerType: null,
      customerTypeId: null,
      name: null,
      lastname: null,
      mobileNumber: {
        prefix: null,
        number: null
      },
      otherNumber: {
        prefix: null,
        number: null
      },
      otherEmail: null,
      billing: null,
      billingName: null,
      billingLastname: null,
      useRegistry: null,
      fiscalCode: null,
      billingCountry: null,
      billingZip: null,
      billingAddress: null,
      companyId: null,
      company: null,
      boats: [],
      steps: []
    }
    this.storageService.setObject(this.STORAGE_PREFIX + 'user', user);
    this.user.next(user);
  }

  public setUser(passedUser: any) {
    const user = {
      id: null,
      email: null,
      password: null,
      terms: false,
      customerType: null,
      customerTypeId: null,
      name: null,
      lastname: null,
      mobileNumber: {
        prefix: null,
        number: null
      },
      otherNumber: {
        prefix: null,
        number: null
      },
      otherEmail: null,
      billing: null,
      useRegistry: null,
      fiscalCode: null,
      steps: []
    }

    const obj = { ...user, ...passedUser };
    // console.log('setUser obj', obj);
    this.storageService.setObject(this.STORAGE_PREFIX + 'user', obj);
  }

  public createUser(user) {
    return new Promise(async resolve => {
      const userData = {
      };
      if ((user.email) && (user.password) && (user.password)) {
        userData['email'] = user.email;
        userData['password'] = user.password;
        userData['language'] = user.language;
        this.userService.createUser(userData).then((res: any) => {
          // console.log(res);
          resolve(res.data);
        }).catch((error: any) => {
          console.error(error);
          resolve(false);
        });
      }
      else {
        resolve(false);
      }
    })
  }

  public updateUser(userId, user) {
    return new Promise(async resolve => {
      this.storageService.getObject(this.STORAGE_PREFIX + 'user').then(res => {
        const obj = { ...res, ...user };
        // console.log('updateUser obj', obj);
        // console.log('updateUser obj.billing', obj.billing);
        this.storageService.setObject(this.STORAGE_PREFIX + 'user', obj);
      });

      const userData = {
        data: {
          type: 'users',
          id: userId,
          attributes: {}
        }
      };
      // Non è prevvisto da API l'aggiornamento dell'email principale
      if (user.email) {
        userData.data.attributes['email'] = user.email;
      }
      // Aggiornamento password disabilitato in APP
      // if (user.password) {
      //   userData.data.attributes['password'] = user.password;
      // }
      if (user.language) {
        userData.data.attributes['language'] = user.language;
      }
      if (user.customerTypeId) {
        userData.data.attributes['customer_type_id'] = parseInt(user.customerTypeId);
      }
      if (user.name) {
        userData.data.attributes['name'] = user.name;
      }
      if (user.lastname) {
        userData.data.attributes['surname'] = user.lastname;
      }
      if ((user.mobileNumber) && (user.mobileNumber.prefix) && (user.mobileNumber.number)) {
        userData.data.attributes['phone'] = ((user.mobileNumber.prefix) ? ('+' + user.mobileNumber.prefix) : '') + '@' + user.mobileNumber.number;
      }
      if ((user.otherNumber) && (user.otherNumber.prefix) && (user.otherNumber.number)) {
        userData.data.attributes['phone_secondary'] = ((user.otherNumber.prefix) ? ('+' + user.otherNumber.prefix) : '') + '@' + user.otherNumber.number;
      }
      if (user.otherEmail) {
        userData.data.attributes['email_secondary'] = user.otherEmail;
      }
      if (user.billing) {
        userData.data.attributes['billing'] = user.billing;
      }
      if (user.companyId) {
        userData.data.attributes['company_id'] = user.companyId;
      }
      if (user.billingName) {
        userData.data.attributes['billing_name'] = user.billingName;
      }
      if (user.billingLastname) {
        userData.data.attributes['billing_surname'] = user.billingLastname;
      }
      if (user.fiscalCode) {
        userData.data.attributes['fiscal_code'] = user.fiscalCode;
      }
      if (user.billingCountry) {
        userData.data.attributes['billing_country'] = user.billingCountry;
      }
      if (user.billingZip) {
        userData.data.attributes['billing_cap'] = user.billingZip;
      }
      if (user.billingAddress) {
        userData.data.attributes['billing_address'] = user.billingAddress;
      }
      // Proprietà non aggiornabile da APP
      // if (user.type) {
      //   userData.data.attributes['type'] = user.type;
      // }

      this.userService.updateUser(userId, userData).then((res: any) => {
        // console.log(res);
        resolve(res.data);
      }).catch((error: any) => {
        console.error(error);
        resolve(false);
      });
    })

  }

  public getProfileCompleteness() {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(this.profileCompletenessData);
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    })
  }

  public resetLocations() {
    return new Promise(async (resolve, reject) => {
      try {
        this.locationsData = [];
        this.locations$.next(this.locationsData);
        resolve(true);
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    })
  }

  public loadLocations() {
    // console.log('loading boat');
    return new Promise(async (resolve, reject) => {
      try {
        this.locationsData = [];
        const locations = await this.optionService.getLocations();
        await this.asyncForEach(locations.data, async location => {
          const locationData = {
            id: location.id,
            name: location.attributes.name,
            address: location.attributes.address,
            city: location.attributes.city,
            country: location.attributes.country
          }
          this.locationsData.push(locationData);
        });
        // console.log('locationsData length - pre', this.locationsData.length);
        this.locationsData = uniqBy(this.locationsData, 'id');
        // console.log('locationsData length - post', this.locationsData.length);
        this.locations$.next(uniqBy(this.locationsData, 'id'));
        resolve(true);
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    })
  }

  public getLocation(id: string) {
    return new Promise<Location>(async resolve => {
      this.optionService.getLocation(id).then((res: any) => {
        // console.log(res);
        const locationDataAttributes = res.data.attributes;
        const location = {
          id: res.data.id,
          name: ((locationDataAttributes.name) ? (locationDataAttributes.name) : ''),
          address: ((locationDataAttributes.address) ? (locationDataAttributes.address) : ''),
          city: ((locationDataAttributes.city) ? (locationDataAttributes.city) : ''),
          country: ((locationDataAttributes.country) ? (locationDataAttributes.country) : '')
        }

        resolve(location);
      }).catch((error: any) => {
        console.error(error);
        resolve(null);
      });
    })
  }

  public resetBoats() {
    return new Promise(async (resolve, reject) => {
      try {
        this.boatsData = [];
        this.boats.next(this.boatsData);
        resolve(true);
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    })
  }

  public loadBoats() {
    // console.log('loading boat');
    return new Promise(async (resolve, reject) => {
      try {
        this.boatsData = [];
        const boats = await this.boatService.getBoats();
        await this.asyncForEach(boats.data, async boat => {
          const boatData = {
            id: boat.id,
            name: boat.attributes.name,
            model: boat.attributes.model,
            dockyard: boat.attributes.dockyard,
            hull_number: boat.attributes.hull_number,
            full_access: boat.attributes.full_access,
            has_equipments: boat.attributes.has_equipments,
            equipments: [],
            has_locations: boat.attributes.has_locations,
            has_favorite_location: boat.attributes.has_favorite_location,
            locations: [],
            documents: [],
            thumbs: boat.attributes.thumbs
          }
          this.boatsData.push(boatData);
        });
        // console.log('boatsData length - pre', this.boatsData.length);
        this.boatsData = uniqBy(this.boatsData, 'id');
        // console.log('boatsData length - post', this.boatsData.length);
        this.boats.next(uniqBy(this.boatsData, 'id'));
        resolve(true);
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    })
  }

  public getBoat(id: string) {
    return new Promise<Boat>(async resolve => {
      // const boat = find(this.boatsData, function(o) { return o.id == id; })
      this.boatService.getBoat(id).then((res: any) => {
        // console.log(res);
        const boatDataAttributes = res.data.attributes;
        const boat = {
          id: res.data.id,
          name: ((boatDataAttributes.name) ? (boatDataAttributes.name) : ''),
          model: ((boatDataAttributes.model) ? (boatDataAttributes.model) : ''),
          dockyard: ((boatDataAttributes.dockyard) ? (boatDataAttributes.dockyard) : ''),
          hull_number: ((boatDataAttributes.hull_number) ? (boatDataAttributes.hull_number) : ''),
          full_access: ((boatDataAttributes.full_access) ? (boatDataAttributes.full_access) : false),
          has_favorite_location: ((boatDataAttributes.has_favorite_location || (0 === boatDataAttributes.has_favorite_location)) ? (boatDataAttributes.has_favorite_location) : null),
          equipments: ((boatDataAttributes.equipments) ? (boatDataAttributes.equipments) : []),
          locations: ((boatDataAttributes.locations) ? (boatDataAttributes.locations) : []),
          documents: ((boatDataAttributes.documents) ? (boatDataAttributes.documents) : []),

          image: null,
          completed: false,
          steps: null
        }

        resolve(boat);
      }).catch((error: any) => {
        console.error(error);
        resolve(null);
      });
    })
  }

  public createBoat(boat) {
    return new Promise(async resolve => {
      if (boat) {
        const boatData = {
          data: {
            type: 'boats',
            attributes: {}
          }
        };
        if (boat.name) {
          boatData.data.attributes['name'] = boat.name;
        }
        if (boat.dockyard) {
          boatData.data.attributes['dockyard'] = boat.dockyard;
        }
        if (boat.model) {
          boatData.data.attributes['model'] = boat.model;
        }
        if (boat.hull_number) {
          boatData.data.attributes['hull_number'] = boat.hull_number;
        }
        this.boatService.createBoat(boatData).then((res: any) => {
          // console.log(res);
          resolve(res.data);
        }).catch((error: any) => {
          console.error(error);
          resolve(false);
        });
      }
      else {
        resolve(null);
      }
    })
  }

  public updateBoat(boatId, boat) {
    return new Promise(async resolve => {
      const boatData = {
        data: {
          type: 'boats',
          id: boatId,
          attributes: {}
        }
      };
      if (boat.name) {
        boatData.data.attributes['name'] = boat.name;
      }
      if (boat.dockyard) {
        boatData.data.attributes['dockyard'] = boat.dockyard;
      }
      if (boat.model) {
        boatData.data.attributes['model'] = boat.model;
      }
      if (boat.hull_number) {
        boatData.data.attributes['hull_number'] = boat.hull_number;
      }
      if ((0 === boat.has_favorite_location) || (1 === boat.has_favorite_location)) {
        boatData.data.attributes['has_favorite_location'] = boat.has_favorite_location;
      }
      this.boatService.updateBoat(boatId, boatData).then((res: any) => {
        // console.log(res);
        resolve(res.data);
      }).catch((error: any) => {
        console.error(error);
        resolve(false);
      });
    })
  }

  public addBoatDocument(boatId, document) {
    return new Promise(async resolve => {
      const documentData = {
        data: {
          attributes: {
            title: 'boat_image',
            type: 'boat_image',
            entity_type: 'boat',
            entity_id: boatId,
            filename: 'boat_image',
          }
        }
      };
      if (document.filename) {
        documentData.data.attributes['filename'] = document.filename;
      }
      else {
        documentData.data.attributes['filename'] = 'boat.jpeg';
      }
      if (document.file) {
        documentData.data.attributes['file'] = document.file;
      }
      this.documentService.createDocument(documentData).then((res: any) => {
        // console.log(res);
        resolve(res.data);
      }).catch((error: any) => {
        console.error(error);
        resolve(false);
      });
    })
  }

  public createBoatLocation(location) {
    return new Promise(async resolve => {
      const data = {
        data: {
          type: 'boat-location',
          attributes: {}
        }
      };
      if (location.boat_id) {
        data.data.attributes['boat_id'] = location.boat_id;
      }
      if (location.location_type) {
        data.data.attributes['location_type'] = location.location_type;
      }
      if (location.location_name) {
        data.data.attributes['location_name'] = location.location_name;
      }
      if (location.location_city) {
        data.data.attributes['location_city'] = location.location_city;
      }
      if (location.location_country) {
        data.data.attributes['location_country'] = location.location_country;
      }
      if (location.favorite) {
        data.data.attributes['favorite'] = location.favorite;
      }
      if (location.favorite_pier) {
        data.data.attributes['favorite_pier'] = location.favorite_pier;
      }
      if (location.favorite_mooring) {
        data.data.attributes['favorite_mooring'] = location.favorite_mooring;
      }
      this.boatService.createBoatLocation(data).then((res: any) => {
        // console.log(res);
        resolve(res.data);
      }).catch((error: any) => {
        console.error(error);
        resolve(false);
      });
    })
  }

  public removeBoatLocation(boatId, locationId) {
    return new Promise(async resolve => {
      const data = {
        data: {
          type: 'boat-location',
          attributes: {}
        }
      };
      if (boatId) {
        data.data.attributes['boat_id'] = boatId;
      }
      if (locationId) {
        data.data.attributes['id'] = locationId;
      }
      this.boatService.removeBoatLocation(data).then((res: any) => {
        resolve(true);
      }).catch((error: any) => {
        console.error(error);
        resolve(false);
      });
    })
  }

  public associateBoatEquipment(boatId: number, equipmentId: number, serialNumber: string, installationDate: string, notes: string) {
    return new Promise(async resolve => {
      const data = {
        data: {
          type: 'boat-equipment',
          attributes: {}
        }
      };
      if (boatId) {
        data.data.attributes['boat_id'] = boatId;
      }
      if (equipmentId) {
        data.data.attributes['equipment_id'] = equipmentId;
      }
      if (serialNumber) {
        data.data.attributes['serial_number'] = serialNumber;
      }
      if (installationDate) {
        data.data.attributes['installation'] = installationDate;
      }
      if (notes) {
        data.data.attributes['notes'] = notes;
      }
      this.boatService.associateBoatEquipment(data).then((res: any) => {
        // console.log(res);
        resolve(res.data);
      }).catch((error: any) => {
        console.error(error);
        resolve(false);
      });
    })
  }

  public updateBoatEquipment(boatId: number, equipmentId: number, serialNumber: string, installationDate: string, notes: string) {
    return new Promise(async resolve => {
      const data = {
        data: {
          type: 'boat-equipment',
          id: String(equipmentId),
          attributes: {}
        }
      };
      if (boatId) {
        data.data.attributes['boat_id'] = boatId;
      }
      if (serialNumber) {
        data.data.attributes['serial_number'] = serialNumber;
      }
      if (installationDate) {
        data.data.attributes['installation'] = installationDate;
      }
      if (notes) {
        data.data.attributes['notes'] = notes;
      }
      this.boatService.updateBoatEquipment(equipmentId, data).then((res: any) => {
        // console.log('res', res);
        resolve(res.data);
      }).catch((error: any) => {
        console.error(error);
        resolve(false);
      });
    })
  }

  public removeBoatEquipment(boatId: number, equipmentId: number) {
    return new Promise(async resolve => {
      const data = {
        data: {
          type: 'boat-equipment',
          attributes: {}
        }
      };
      if (boatId) {
        data.data.attributes['boat_id'] = Number(boatId);
      }
      if (equipmentId) {
        data.data.attributes['id'] = Number(equipmentId);
      }
      this.boatService.removeBoatEquipment(data).then((res: any) => {
        resolve(true);
      }).catch((error: any) => {
        console.error(error);
        resolve(false);
      });
    })
  }

  public createBoatManagementRequest(email: string, notes: string) {
    return new Promise(async (resolve, reject) => {
      const data = {
        data: {
          type: 'boat-management-requests',
          attributes: {}
        }
      };
      if (email) {
        data.data.attributes['email'] = email;
      }
      if (notes) {
        data.data.attributes['notes'] = notes;
      }
      this.boatService.createBoatManagementRequest(data).then((res: any) => {
        // console.log(res);
        resolve(res);
      }).catch((error: any) => {
        console.error(error);
        reject();
      });
    })
  }

  public getBoatManagementRequests() {
    return new Promise<any[]>(async resolve => {
      this.boatService.getBoatManagementRequests().then((res: any) => {
        // console.log(res);
        resolve(res.data);
      }).catch((error: any) => {
        console.error(error);
        resolve(null);
      });
    })
  }

  public getBoatManagementRequest(requestId: number) {
    return new Promise<any[]>(async resolve => {
      this.boatService.getBoatManagementRequest(requestId).then((res: any) => {
        // console.log(res);
        resolve(res.data);
      }).catch((error: any) => {
        console.error(error);
        resolve(null);
      });
    })
  }

  public updateBoatManagementRequest(requestId: number, request: any) {
    return new Promise<any>(async resolve => {
      const requestData = {
        data: {
          type: 'boat-management-requests',
          id: String(requestId),
          attributes: {}
        }
      };
      if (request.results) {
        requestData.data.attributes['results'] = request.results;
      }

      this.boatService.updateBoatManagementRequest(requestId, requestData).then((res: any) => {
        // console.log(res);
        resolve(res.data);
      }).catch((error: any) => {
        console.error(error);
        resolve(null);
      });
    })
  }

  public getDocument(documentId: number) {
    return new Promise<any>(async resolve => {
      this.documentService.getDocument(documentId).then((res: any) => {
        // console.log(res);
        resolve(res.data);
      }).catch((error: any) => {
        console.error(error);
        resolve(null);
      });
    })
  }

  public getDocumentBase64(documentId: number) {
    return new Promise<any>(async resolve => {
      this.documentService.getDocumentBase64(documentId).then((res: any) => {
        // console.log(res);
        resolve(res.data);
      }).catch((error: any) => {
        console.error(error);
        resolve(null);
      });
    })
  }

  public deleteDocument(documentId: number) {
    return new Promise<any>(async resolve => {
      this.documentService.deleteDocument(documentId).then((res: any) => {
        // console.log(res);
        resolve(true);
      }).catch((error: any) => {
        console.error(error);
        resolve(null);
      });
    })
  }

  public getRequestTypes() {
    return new Promise<any[]>(async (resolve, reject) => {
      try {
        resolve(this.requestTypesData);
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    })
  }

  public getRequestStatuses() {
    return new Promise<any[]>(async (resolve, reject) => {
      try {
        resolve(this.requestStatusesData);
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    })
  }

  public getRequestStatus(requestStatusId: number) {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const requestStatusesIndex = findIndex(this.requestStatusesData, (s) => {
          return s.id == requestStatusId;
        });
        if (requestStatusesIndex >= 0) {
          resolve(this.requestStatusesData[requestStatusesIndex]);
        }
        else {
          resolve(null);
        }
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    })
  }

  public resetRequests() {
    return new Promise(async (resolve, reject) => {
      try {
        this.requestsData = [];
        this.requests.next(this.requestsData);
        resolve(true);
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    })
  }

  getProgressStep(internal_status: string, requestStatusId: number) {
    let progressStep = 0;
    if ('report' == internal_status) {
      const requestStatusesIndex = findIndex(this.requestStatusesData, (s) => {
        return s.id == requestStatusId;
      });
      if (requestStatusesIndex >= 0) {
        if (this.requestStatusesData[requestStatusesIndex]) {
          switch (this.requestStatusesData[requestStatusesIndex].value) {
            case 'new':
            case 'sent':
              progressStep = 1;
              break;
            case 'read':
              progressStep = 2;
              break;
            case 'progress':
              progressStep = 3;
              break;
            case 'completed':
              progressStep = 4;
              break;
          }
        }
      }
    }
    return progressStep;
  }

  public loadRequests(filters = []) {
    return new Promise(async (resolve, reject) => {
      try {
        // console.log('loadRequests');
        let requestStatuses = [];
        const requestStatusesData = await this.optionService.getRequestStatuses();
        await this.asyncForEach(requestStatusesData.data, element => {
          requestStatuses.push({
            id: parseInt(element.id),
            value: element.attributes.value,
            // value: parseInt( element.id ),
            name: element.attributes.name
          });
        });
        this.requestStatusesData = requestStatuses.sort((a, b) => b.value - a.value);
        this.requestStatuses.next(requestStatuses.sort((a, b) => b.value - a.value));

        let requests = [];
        const requestsData = await this.requestService.getRequests(filters);
        await this.asyncForEach(requestsData.data, async element => {
          // console.log('requestsData', requestsData);
          const boatId = element.attributes.boat_id;
          const requestStatusValue = this.getProgressStep(element.attributes.internal_status, element.attributes.request_status_id);

          if (!element.attributes.hide) {
            requests.push({
              id: parseInt(element.id),
              boat_id: boatId,
              created_at: element.attributes.created_at,
              last_update: ((element.attributes.last_update) ? element.attributes.last_update : element.attributes.created_at),
              dispatch_date: element.attributes.dispatch_date,
              boat: element.attributes.boat,
              topic_id: element.attributes.topic_id,
              request_type: element.attributes.request_type,
              request_type_id: element.attributes.request_type_id,
              request_status: element.attributes.request_status,
              request_status_id: element.attributes.request_status_id,
              request_status_value: requestStatusValue,
              topic: element.attributes.topic,
              equipment: element.attributes.equipment,
              location: element.attributes.location,
              assistance_location_id: element.attributes.assistance_location_id,
              feedback_final_notes: element.attributes.feedback_final_notes,
              internal_status: element.attributes.internal_status,
              moving_next_days: element.attributes.moving_next_days,
              on_board_access_time: element.attributes.on_board_access_time,
              on_board_special_access: element.attributes.on_board_special_access,
              referent: element.attributes.referent,
              referent_phone_prefix: ((element.attributes.referent_phone) ? element.attributes.referent_phone.split('@')[0].replace('+', '') : null),
              referent_phone: ((element.attributes.referent_phone) ? element.attributes.referent_phone.split('@')[1] : null)
            });
          }
        });
        this.requestsData = orderBy(uniqBy(requests, 'id'), ['id'], ['desc']);
        this.requests.next(this.requestsData);
        resolve(true);
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    })
  }

  public getRequests(filters = []) {
    return new Promise<any[]>(async resolve => {
      this.requestService.getRequests(filters).then((res: any) => {
        // console.log(res);
        resolve(res.data);
      }).catch((error: any) => {
        console.error(error);
        resolve(null);
      });
    })
  }

  public getRequest(requestId: number) {
    return new Promise<any[]>(async resolve => {
      this.requestService.getRequest(requestId).then((res: any) => {
        // console.log(res);
        if (!res.data.attributes.last_update) {
          res.data.attributes.last_update = res.data.attributes.created_at;
        }
        resolve(res.data.attributes);
      }).catch((error: any) => {
        console.error(error);
        resolve(null);
      });
    })
  }

  public createRequest(boat_id: number, equipment_id: number, flow_type_id: number, locationId: number = null, flowId: number = null) {
    return new Promise(async resolve => {
      const data = {
        data: {
          type: 'requests',
          attributes: {}
        }
      };
      if (boat_id) {
        data.data.attributes['boat_id'] = boat_id;
      }
      if (equipment_id) {
        data.data.attributes['equipment_id'] = equipment_id;
      }
      if (flow_type_id) {
        data.data.attributes['flow_type_id'] = flow_type_id;
      }
      if (locationId) {
        data.data.attributes['location_id'] = locationId;
      }
      if (flowId) {
        data.data.attributes['flow_id'] = flowId;
      }

      this.requestService.createRequest(data).then((res: any) => {
        // console.log(res);
        resolve(res.data);
      }).catch((error: any) => {
        console.error(error);
        resolve(false);
      });
    })
  }

  public duplicateRequest(request_id: number, pages: number[]) {
    return new Promise(async resolve => {
      const data = {
        data: {
          type: 'requests',
          id: request_id,
          attributes: {}
        }
      };
      if (pages) {
        data.data.attributes['pages'] = pages;
      }

      this.requestService.duplicateRequest(request_id, data).then((res: any) => {
        // console.log(res);
        resolve(res.data);
      }).catch((error: any) => {
        console.error(error);
        resolve(false);
      });
    })
  }

  public getRequestTopics(requestId: number) {
    return new Promise<any[]>(async resolve => {
      this.requestService.getRequestTopics(requestId).then((res: any) => {
        // console.log(res);
        resolve(res.data);
      }).catch((error: any) => {
        console.error(error);
        resolve(null);
      });
    })
  }

  public skipRequestTopic(requestId: number) {
    return new Promise<any>(async resolve => {
      this.requestService.skipRequestTopic(requestId).then((res: any) => {
        // console.log(res);
        resolve(res.data);
      }).catch((error: any) => {
        console.error(error);
        resolve(null);
      });
    })
  }

  public setRequestTopic(requestId: number, topicId: number) {
    return new Promise<any>(async resolve => {
      const data = {
        data: {
          type: 'requests',
          id: String(requestId),
          attributes: {}
        }
      };
      if (topicId) {
        data.data.attributes['topic_id'] = topicId;
      }
      // console.log('data', data);

      this.requestService.setRequestTopic(requestId, data).then((res: any) => {
        // console.log(res);
        resolve(res.data);
      }).catch((error: any) => {
        console.error(error);
        resolve(null);
      });
    })
  }

  public getRequestPreliminaryPages(requestId: number) {
    return new Promise<any[]>(async resolve => {
      this.requestService.getRequestPreliminaryPages(requestId).then((res: any) => {
        // console.log(res);
        resolve(res.data);
      }).catch((error: any) => {
        console.error(error);
        resolve(null);
      });
    })
  }

  public updateRequestPreliminaryPages(requestId: number) {
    return new Promise<any[]>(async resolve => {
      this.requestService.setRequestPreliminaryPage(requestId).then((res: any) => {
        // console.log(res);
        resolve(res.data);
      }).catch((error: any) => {
        console.error(error);
        resolve(null);
      });
    })
  }

  public setRequestSubmission(requestId: number, submissionId: number, resultData: any, noteData: string, attachmentData: string) {
    return new Promise<any[]>(async resolve => {
      const data = {
        data: {
          type: 'request-submissions',
          id: String(submissionId),
          attributes: {}
        }
      };
      data.data.attributes['request_id'] = requestId;
      data.data.attributes['result'] = resultData;
      data.data.attributes['note'] = noteData;
      data.data.attributes['attachment'] = attachmentData;

      this.requestService.setRequestSubmission(submissionId, data).then((res: any) => {
        // console.log(res);
        resolve(res.data);
      }).catch((error: any) => {
        console.error(error);
        resolve(null);
      });
    })
  }

  public getRequestPages(requestId: number) {
    return new Promise<any[]>(async resolve => {
      this.requestService.getRequestPages(requestId).then((res: any) => {
        // console.log(res);
        if (res && res.data && res.data.attributes) {
          resolve(res.data.attributes);
        }
        else {
          resolve([]);
        }
      }).catch((error: any) => {
        console.error(error);
        resolve(null);
      });
    })
  }

  public getRequestPage(requestId: number, pageId: number) {
    return new Promise<any>(async resolve => {
      this.requestService.getRequestPage(requestId, pageId).then((res: any) => {
        // console.log(res);
        if (res && res.data && res.data.attributes) {
          resolve(res.data.attributes);
        }
        else {
          resolve([]);
        }
      }).catch((error: any) => {
        console.error(error);
        resolve(null);
      });
    })
  }

  public updateRequestPage(requestId: number, pageId: number) {
    return new Promise<any[]>(async resolve => {
      this.requestService.setRequestPage(requestId, pageId).then((res: any) => {
        resolve(res);
      }).catch((error: any) => {
        console.error(error);
        resolve(null);
      });
    })
  }

  public updateRequestNotes(requestId: number, feedbackFinalNotes: string) {
    return new Promise<any>(async resolve => {
      this.requestService.setRequestNotes(requestId, feedbackFinalNotes).then((res: any) => {
        resolve(res);
      }).catch((error: any) => {
        console.error(error);
        resolve(null);
      });
    })
  }

  public updateRequestLocalization(
    requestId: number,
    referentName: string,
    referentPhonePrefix: string,
    referentPhone: string,
    assistanceLocation: any,
    movingNextDays: string,
    onBoardAccessTime: string,
    onBoardSpecialAccess: string
  ) {
    return new Promise<any>(async resolve => {
      const dataAttributes = {
        referent: {
          name: referentName,
          phone: (((referentPhonePrefix) ? ('+' + referentPhonePrefix) : '') + '@' + referentPhone)
        },
        assistance_location: assistanceLocation,
        moving_next_days: movingNextDays,
        on_board_access_time: onBoardAccessTime,
        on_board_special_access: onBoardSpecialAccess,
      };

      this.requestService.setRequestLocalization(requestId, dataAttributes).then((res: any) => {
        resolve(res);
      }).catch((error: any) => {
        console.error(error);
        resolve(null);
      });
    })
  }

  public getRequestReport(requestId: number) {
    return new Promise<any>(async resolve => {
      this.requestService.getRequestReport(requestId).then((res: any) => {
        // console.log(res);
        resolve(res);
      }).catch((error: any) => {
        console.error(error);
        resolve(null);
      });
    })
  }

  public getRequestReportBase64(requestId: number) {
    return new Promise<string>(async resolve => {
      this.requestService.getRequestReportBase64(requestId).then((res: any) => {
        // console.log(res);
        if (res && res.data && res.data.attributes && res.data.attributes) {
          resolve(res.data.attributes.report);
        }
        else {
          resolve(null);
        }
      }).catch((error: any) => {
        console.error(error);
        resolve(null);
      });
    })
  }

  public getFeedbackPages(requestId: number) {
    return new Promise<any[]>(async resolve => {
      this.requestService.getFeedbackPages(requestId).then((res: any) => {
        // console.log(res);
        if (res && res.data && res.data.attributes) {
          resolve(res.data.attributes);
        }
        else {
          resolve([]);
        }
      }).catch((error: any) => {
        console.error(error);
        resolve(null);
      });
    })
  }

  public getFeedbackPage(requestId: number, pageId: number) {
    return new Promise<any>(async resolve => {
      this.requestService.getFeedbackPage(requestId, pageId).then((res: any) => {
        // console.log(res);
        if (res && res.data && res.data.attributes) {
          resolve(res.data.attributes);
        }
        else {
          resolve([]);
        }
      }).catch((error: any) => {
        console.error(error);
        resolve(null);
      });
    })
  }

  public updateFeedbackPage(requestId: number, pageId: number) {
    return new Promise<any[]>(async resolve => {
      this.requestService.setFeedbackPage(requestId, pageId).then((res: any) => {
        resolve(res);
      }).catch((error: any) => {
        console.error(error);
        resolve(null);
      });
    })
  }

  public updateFeedbackNotes(requestId: number, feedbackNotes: string) {
    return new Promise<any>(async resolve => {
      this.requestService.setFeedbackNotes(requestId, feedbackNotes).then((res: any) => {
        resolve(res);
      }).catch((error: any) => {
        console.error(error);
        resolve(null);
      });
    })
  }

  public setFeedbackSubmission(requestId: number, submissionId: number, resultData: any, noteData: string, attachmentData: string) {
    return new Promise<any[]>(async resolve => {
      const data = {
        data: {
          type: 'feedback-submission',
          id: String(submissionId),
          attributes: {}
        }
      };
      // console.log('setFeedbackSubmission value', resultData);
      data.data.attributes['request_id'] = requestId;
      data.data.attributes['result'] = resultData.result.value;
      data.data.attributes['note'] = noteData;
      data.data.attributes['attachment'] = attachmentData;

      this.requestService.setFeedbackSubmission(submissionId, data).then((res: any) => {
        // console.log(res);
        resolve(res.data);
      }).catch((error: any) => {
        console.error(error);
        resolve(null);
      });
    })
  }

  public writeToUs(name: string, email: string, note: string) {
    return new Promise<any>(async resolve => {
      const messageData = {
        data: {
          type: 'write-capatayn',
          attributes: {}
        }
      };
      if (name && email && note) {
        const language = await this.languageService.getLanguage();
        messageData.data.attributes['language'] = language;
        messageData.data.attributes['name'] = name;
        messageData.data.attributes['email'] = email;
        messageData.data.attributes['note'] = note;
        this.optionService.writeToUs(messageData).then((res: any) => {
          // console.log(res);
          resolve(res.data);
        }).catch((error: any) => {
          console.error(error);
          resolve(null);
        });
      }
      else {
        resolve(false);
      }
    })
  }

}
