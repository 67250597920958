import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./guards/auth.guard";
import { CommonGuard } from "./guards/common.guard";
import { LoginGuard } from "./guards/login.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "auth",
    pathMatch: "full"
  },
  {
    path: "auth",
    loadChildren: () =>
      import("./pages/login/login.module").then(
        (m) => m.LoginPageModule
      ),
    canActivate: [LoginGuard]
  },
  {
    path: "home",
    loadChildren: () =>
      import("./pages/home/home.module").then(
        (m) => m.HomePageModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: "requests",
    loadChildren: () =>
      import("./pages/requests/requests.module").then(
        (m) => m.RequestsPageModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: "boats",
    loadChildren: () =>
      import("./pages/boats/boats.module").then((m) => m.BoatsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: "profile",
    loadChildren: () =>
      import("./pages/profile/profile.module").then((m) => m.ProfilePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: "profile/edit/:step",
    loadChildren: () =>
      import("./pages/profile-edit/profile-edit.module").then(
        (m) => m.ProfileEditPageModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: "boat/:boatId",
    loadChildren: () =>
      import("./pages/boat/boat.module").then((m) => m.BoatPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: "boat/:boatId/edit/:step",
    loadChildren: () =>
      import("./pages/boat-edit/boat-edit.module").then(
        (m) => m.BoatEditPageModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'boat/:boatId/equipments',
    loadChildren: () => import('./pages/boat-equipments/boat-equipments.module').then(m => m.BoatEquipmentsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'boat/:boatId/equipment/:equipmentTypeId/:equipmentBrandId',
    loadChildren: () => import('./pages/boat-equipment-edit/boat-equipment-edit.module').then(m => m.BoatEquipmentEditPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'boat-share/request',
    loadChildren: () => import('./pages/boat-share-request/boat-share-request.module').then(m => m.BoatShareRequestPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'boat-share/response/:requestId',
    loadChildren: () => import('./pages/boat-share-response/boat-share-response.module').then(m => m.BoatShareResponsePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: "flow/request",
    loadChildren: () =>
      import("./pages/flow-request/flow-request.module").then(
        (m) => m.FlowRequestPageModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: "flow/request/:requestId/pages",
    loadChildren: () =>
      import("./pages/flow-request-pages/flow-request-pages.module").then(
        (m) => m.FlowRequestPagesPageModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: "flow/:requestTypeId/location",
    loadChildren: () =>
      import("./pages/flow-location/flow-location.module").then(
        (m) => m.FlowLocationPageModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'flow/:requestTypeId/location/:locationId/flow',
    loadChildren: () => import('./pages/flow-list/flow-list.module').then(m => m.FlowListPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: "flow/:requestTypeId/boat",
    loadChildren: () =>
      import("./pages/flow-boat/flow-boat.module").then(
        (m) => m.FlowBoatPageModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'flow/:requestTypeId/boat/:boatId/equipment',
    loadChildren: () => import('./pages/flow-equipment/flow-equipment.module').then(m => m.FlowEquipmentPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'flow/:requestTypeId/boat/:boatId/equipment/:equipmentId/missing',
    loadChildren: () => import('./pages/flow-missing/flow-missing.module').then(m => m.FlowMissingPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'request/:requestId',
    loadChildren: () => import('./pages/request/request.module').then(m => m.RequestPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'request/:requestId/topic',
    loadChildren: () => import('./pages/request-topic/request-topic.module').then(m => m.RequestTopicPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'request/:requestId/preliminary',
    loadChildren: () => import('./pages/request-preliminary/request-preliminary.module').then(m => m.RequestPreliminaryPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'request/:requestId/page/:pageId',
    loadChildren: () => import('./pages/request-page/request-page.module').then(m => m.RequestPagePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'request/:requestId/notes',
    loadChildren: () => import('./pages/request-notes/request-notes.module').then(m => m.RequestNotesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'request/:requestId/localization',
    loadChildren: () => import('./pages/request-localization/request-localization.module').then(m => m.RequestLocalizationPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'password/reset',
    loadChildren: () => import('./pages/password-reset/password-reset.module').then(m => m.PasswordResetPageModule)
  },
  {
    path: 'email/reset',
    loadChildren: () => import('./pages/email-reset/email-reset.module').then(m => m.EmailResetPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./pages/contact-us/contact-us.module').then(m => m.ContactUsPageModule),
    canActivate: [CommonGuard]
  },
  {
    path: 'feedbacks',
    loadChildren: () => import('./pages/feedbacks/feedbacks.module').then(m => m.FeedbacksPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'feedback/:requestId',
    loadChildren: () => import('./pages/feedback/feedback.module').then(m => m.FeedbackPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'feedback/:requestId/page/:pageId',
    loadChildren: () => import('./pages/feedback-page/feedback-page.module').then(m => m.FeedbackPagePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'feedback/:requestId/notes',
    loadChildren: () => import('./pages/feedback-notes/feedback-notes.module').then(m => m.FeedbackNotesPageModule),
    canActivate: [AuthGuard]
  },

  {
    path: "statics",
    loadChildren: () =>
      import("./statics/home/home.module").then((m) => m.HomePageModule)
  },
  {
    path: "statics/splash",
    loadChildren: () =>
      import("./statics/splash/splash.module").then((m) => m.SplashPageModule)
  },
  {
    path: "statics/onboarding",
    loadChildren: () =>
      import("./statics/onboarding/onboarding.module").then(
        (m) => m.OnboardingPageModule
      )
  },
  {
    path: "statics/login",
    loadChildren: () =>
      import("./statics/login/login.module").then((m) => m.LoginPageModule)
  },
  {
    path: "statics/homepage",
    loadChildren: () =>
      import("./statics/homepage/homepage.module").then(
        (m) => m.HomepagePageModule
      )
  },
  {
    path: "statics/richieste-es",
    loadChildren: () =>
      import("./statics/richieste-es/richieste-es.module").then(
        (m) => m.RichiesteEsPageModule
      )
  },
  {
    path: "statics/barche-es",
    loadChildren: () =>
      import("./statics/barche-es/barche-es.module").then(
        (m) => m.BarcheEsPageModule
      )
  },
  {
    path: "statics/profilo",
    loadChildren: () =>
      import("./statics/profilo/profilo.module").then(
        (m) => m.ProfiloPageModule
      )
  },
  {
    path: "statics/profilo-credenziali",
    loadChildren: () =>
      import("./statics/profilo-credenziali/profilo-credenziali.module").then(
        (m) => m.ProfiloCredenzialiPageModule
      )
  },
  {
    path: "statics/profilo-anagrafica",
    loadChildren: () =>
      import("./statics/profilo-anagrafica/profilo-anagrafica.module").then(
        (m) => m.ProfiloAnagraficaPageModule
      )
  },
  {
    path: "statics/profilo-fatturazione",
    loadChildren: () =>
      import("./statics/profilo-fatturazione/profilo-fatturazione.module").then(
        (m) => m.ProfiloFatturazionePageModule
      )
  },
  {
    path: "statics/profilo-modal-success",
    loadChildren: () =>
      import(
        "./statics/profilo-modal-success/profilo-modal-success.module"
      ).then((m) => m.ProfiloModalSuccessPageModule)
  },
  {
    path: "statics/barche",
    loadChildren: () =>
      import("./statics/barche/barche.module").then((m) => m.BarchePageModule)
  },
  {
    path: "statics/nuova-barca",
    loadChildren: () =>
      import("./statics/nuova-barca/nuova-barca.module").then(
        (m) => m.NuovaBarcaPageModule
      )
  },
  {
    path: "statics/scheda-barca",
    loadChildren: () =>
      import("./statics/scheda-barca/scheda-barca.module").then(
        (m) => m.SchedaBarcaPageModule
      )
  },
  {
    path: "statics/configurazioni-barca",
    loadChildren: () =>
      import("./statics/configurazioni-barca/configurazioni-barca.module").then(
        (m) => m.ConfigurazioniBarcaPageModule
      )
  },
  {
    path: "statics/localizzazione-barca",
    loadChildren: () =>
      import("./statics/localizzazione-barca/localizzazione-barca.module").then(
        (m) => m.LocalizzazioneBarcaPageModule
      )
  },
  {
    path: "statics/percorso-intercambiabile",
    loadChildren: () =>
      import(
        "./statics/percorso-intercambiabile/percorso-intercambiabile.module"
      ).then((m) => m.PercorsoIntercambiabilePageModule)
  },
  {
    path: "statics/registra-apparato",
    loadChildren: () =>
      import("./statics/registra-apparato/registra-apparato.module").then(
        (m) => m.RegistraApparatoPageModule
      )
  },
  {
    path: "statics/argomento-richiesta",
    loadChildren: () =>
      import("./statics/argomento-richiesta/argomento-richiesta.module").then(
        (m) => m.ArgomentoRichiestaPageModule
      )
  },
  {
    path: "statics/verifiche-preliminari",
    loadChildren: () =>
      import(
        "./statics/verifiche-preliminari/verifiche-preliminari.module"
      ).then((m) => m.VerifichePreliminariPageModule)
  },
  {
    path: "statics/domanda-a-a",
    loadChildren: () =>
      import("./statics/domanda-a-a/domanda-a-a.module").then(
        (m) => m.DomandaAAPageModule
      )
  },
  {
    path: "statics/buttons",
    loadChildren: () =>
      import("./statics/buttons/buttons.module").then(
        (m) => m.ButtonsPageModule
      )
  },
  {
    path: "statics/form-elements",
    loadChildren: () =>
      import("./statics/form-elements/form-elements.module").then(
        (m) => m.FormElementsPageModule
      )
  },
  {
    path: "statics/utilities",
    loadChildren: () =>
      import("./statics/utilities/utilities.module").then(
        (m) => m.UtilitiesPageModule
      )
  },
  {
    path: "statics/modal-success",
    loadChildren: () =>
      import("./statics/modal-success/modal-success.module").then(
        (m) => m.ModalSuccessPageModule
      )
  },
  {
    path: "statics/modal-assistance",
    loadChildren: () =>
      import("./statics/modal-assistance/modal-assistance.module").then(
        (m) => m.ModalAssistancePageModule
      )
  },
  {
    path: "statics/header-footer",
    loadChildren: () =>
      import("./statics/header-footer/header-footer.module").then(
        (m) => m.HeaderFooterPageModule
      )
  },
  {
    path: "statics/states",
    loadChildren: () =>
      import("./statics/states/states.module").then((m) => m.StatesPageModule)
  },
  {
    path: "statics/card",
    loadChildren: () =>
      import("./statics/card/card.module").then((m) => m.CardPageModule)
  },
  {
    path: "statics/attach-photo",
    loadChildren: () =>
      import("./statics/attach-photo/attach-photo.module").then(
        (m) => m.AttachPhotoPageModule
      )
  },
  {
    path: 'statics/mini-guide',
    loadChildren: () => import('./statics/mini-guide/mini-guide.module').then(m => m.MiniGuidePageModule)
  },
  {
    path: 'test',
    loadChildren: () => import('./pages/test/test.module').then(m => m.TestPageModule)
  },
  {
    path: 'statics/selector-modal',
    loadChildren: () => import('./statics/selector-modal/selector-modal.module').then(m => m.SelectorModalPageModule)
  },
  {
    path: 'statics/scegli-apparato',
    loadChildren: () => import('./statics/scegli-apparato/scegli-apparato.module').then(m => m.ScegliApparatoPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
