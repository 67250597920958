<ion-header>
  <div class="ca-modal__header ion-margin-horizontal">
    <div class="ca-modal__header-wrapper">
      <h2 class="ca-modal__title">{{ title }}</h2>
      <button (click)="dismiss()" class="ca-modal__close">
        <i class="far fa-times"></i>
      </button>
    </div>
  </div>
</ion-header>
<ion-content>
  <div class="contmargin ca-modal-assistance ion-margin-bottom">
    <div class="ca-card" [innerHTML]="content">
      <!-- {{ content }} -->
      <!-- <div class="ca-card__title">
        Lorem ipsum dolor sit amet
      </div>
      <div class="ca-card__text">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi consectetur, metus eget gravida sodales, erat ex eleifend tortor, sit amet sollicitudin nibh purus ac purus. Mauris leo felis, scelerisque quis laoreet non, malesuada sed turpis. Maecenas eget molestie erat. Donec volutpat dolor nec justo malesuada, ut condimentum risus mollis. Cras vitae sem magna. Nullam at orci non risus consequat feugiat in vitae justo. Donec egestas hendrerit augue, ac congue arcu sagittis quis. Duis volutpat leo urna, vitae ornare libero mattis ut. Proin commodo diam ac mauris lobortis, sit amet pretium orci ultrices. Suspendisse vulputate sapien a facilisis bibendum. Nullam et tempor turpis, ac dictum ligula. Sed pellentesque dictum rutrum. Praesent iaculis ipsum eros, ultrices finibus quam efficitur consequat. Donec non lorem lectus. Duis mattis metus sed nisl lacinia ultrices. In hac habitasse platea dictumst.
      </div> -->
    </div>
  </div>
  <!-- cta -->
  <!-- <div class="ion-text-center">
    <button class="ca-btn-secondary small ion-margin-bottom" (click)="dismiss()">{{ close }}</button>
  </div> -->
</ion-content>