import { Injectable } from '@angular/core';
import { ErrorHandlerService } from './error-handler.service';
import { JsonApiService } from './jsonapi.service';
import { AuthService } from './auth.service';

@Injectable()
export class UserService {

  constructor(
    private errorHandler: ErrorHandlerService,
    private authService: AuthService,
    private api: JsonApiService
  ) { }

  async getUser(id: string) {
    return new Promise((resolve, reject) => {
      try {
        this.api.getEntity('users', id).subscribe(
          (res: any) => {
            const user = res;
            resolve(user);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  createUser(data: any) {
    return new Promise((resolve, reject) => {
      try {
        this.authService.registerCredentials(data.email, data.password, data.language).subscribe(
          (res: any) => {
            const user = res;
            resolve(user);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  updateUser(id: number, data: any) {
    return new Promise((resolve, reject) => {
      try {
        this.api.updateEntity('users', id, data).subscribe(
          (res: any) => {
            const user = res;
            resolve(user);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  public recoverPassword(email: string) {
    return new Promise((resolve, reject) => {
      try {
        const data = {
          data: {
            type: 'recover-password',
            attributes: {
              email: email
            }
          }
        };
        const path = 'recover-password';
        this.api.postEntityCustomPath(data, path).subscribe(
          (res: any) => {
            const responseData = res.data;
            resolve(responseData);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  public checkCodePassword(email: string, code: string) {
    return new Promise((resolve, reject) => {
      try {
        const data = {
          data: {
            type: 'recover-password',
            attributes: {
              email: email,
              code: code
            }
          }
        };
        const path = 'check-code';
        this.api.postEntityCustomPath(data, path).subscribe(
          (res: any) => {
            const responseData = res;
            resolve(responseData);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  public updatePassword(email: string, code: string, password: string) {
    return new Promise((resolve, reject) => {
      try {
        const data = {
          data: {
            type: 'recover-password',
            attributes: {
              email: email,
              code: code,
              password: password
            }
          }
        };
        const path = 'update-password';
        this.api.postEntityCustomPath(data, path).subscribe(
          (res: any) => {
            const responseData = res;
            resolve(responseData);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  public recoverEmail(email: string) {
    return new Promise((resolve, reject) => {
      try {
        const data = {
          data: {
            type: 'recover-email',
            attributes: {
              email: email
            }
          }
        };
        const path = 'recover-email';
        this.api.postEntityCustomPath(data, path).subscribe(
          (res: any) => {
            const responseData = res.data;
            resolve(responseData);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  public checkCodeEmail(email: string, code: string) {
    return new Promise((resolve, reject) => {
      try {
        const data = {
          data: {
            type: 'recover-email',
            attributes: {
              email: email,
              code: code
            }
          }
        };
        const path = 'check-code-email';
        this.api.postEntityCustomPath(data, path).subscribe(
          (res: any) => {
            const responseData = res;
            resolve(responseData);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  public updateEmail(email: string, code: string, newEmail: string) {
    return new Promise((resolve, reject) => {
      try {
        const data = {
          data: {
            type: 'recover-email',
            attributes: {
              old_email: email,
              code: code,
              email: newEmail
            }
          }
        };
        const path = 'update-email';
        this.api.postEntityCustomPath(data, path).subscribe(
          (res: any) => {
            const responseData = res;
            resolve(responseData);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }
}
