import { Injectable } from '@angular/core';
import { ErrorHandlerService } from './error-handler.service';
import { JsonApiService } from './jsonapi.service';

@Injectable()
export class OptionService {

  constructor(
    private errorHandler: ErrorHandlerService,
    private api: JsonApiService
  ) { }

  async getCustomerTypes() {
    return new Promise<any>((resolve, reject) => {
      try {
        this.api.getCollection('customer-types').subscribe(
          (res: any) => {
            const customerTypes = res;
            resolve(customerTypes);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  async getCustomerType(id: string) {
    return new Promise((resolve, reject) => {
      try {
        this.api.getEntity('customer-types', id).subscribe(
          (res: any) => {
            const customerType = res;
            resolve(customerType);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  async getCompanies() {
    return new Promise<any>((resolve, reject) => {
      try {
        this.api.getCollection('companies').subscribe(
          (res: any) => {
            const companies = res;
            resolve(companies);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  async getCompany(id: string) {
    return new Promise((resolve, reject) => {
      try {
        this.api.getEntity('companies', id).subscribe(
          (res: any) => {
            const company = res;
            resolve(company);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  async getLocations() {
    return new Promise<any>((resolve, reject) => {
      try {
        this.api.getCollection('locations').subscribe(
          (res: any) => {
            const locations = res;
            resolve(locations);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  async getLocation(id: string) {
    return new Promise((resolve, reject) => {
      try {
        this.api.getEntity('locations', id).subscribe(
          (res: any) => {
            const location = res;
            resolve(location);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  async getFlows(filters = []) {
    return new Promise<any>((resolve, reject) => {
      try {
        this.api.getCollection('flows', filters).subscribe(
          (res: any) => {
            const flows = (res.data) ? res.data : null;
            resolve(flows);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  async getEquipments() {
    return new Promise<any>((resolve, reject) => {
      try {
        this.api.getCollection('equipments').subscribe(
          (res: any) => {
            const equipments = res;
            resolve(equipments);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  async searchEquipmentTypes(text: string, lang: string = 'en') {
    return new Promise<any>((resolve, reject) => {
      try {
        const data = {
          data: {
            type: 'equipment-types',
            attributes: {
              search: text,
              lang: lang
            }
          }
        };
        const path = 'equipment-types-search';
        this.api.postEntityCustomPath(data, path).subscribe(
          (res: any) => {
            const equipmentTypes = res.data.attributes;
            resolve(equipmentTypes);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  async searchEquipments(equipmentTypeId: string, brandId: string) {
    return new Promise<any>((resolve, reject) => {
      try {
        const data = {
          data: {
            type: 'equipment',
            attributes: {
              equipment_type_id: equipmentTypeId,
              brand_id: brandId
            }
          }
        };
        const path = 'equipment-search';
        this.api.postEntityCustomPath(data, path).subscribe(
          (res: any) => {
            const equipments = res.data.attributes;
            resolve(equipments);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  async getEquipment(id: string) {
    return new Promise((resolve, reject) => {
      try {
        this.api.getEntity('equipments', id).subscribe(
          (res: any) => {
            const equipment = res;
            resolve(equipment);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  async getRequestTypes() {
    return new Promise<any>((resolve, reject) => {
      try {
        this.api.getCollection('flow-types').subscribe(
          (res: any) => {
            const requestTypes = res;
            resolve(requestTypes);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  async getRequestType(id: string) {
    return new Promise((resolve, reject) => {
      try {
        this.api.getEntity('flow-types', id).subscribe(
          (res: any) => {
            const requestType = res;
            resolve(requestType);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  async getRequestStatuses() {
    return new Promise<any>((resolve, reject) => {
      try {
        this.api.getCollection('request-statuses').subscribe(
          (res: any) => {
            const requestStatuses = res;
            resolve(requestStatuses);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  async getRequestStatus(id: string) {
    return new Promise((resolve, reject) => {
      try {
        this.api.getEntity('request-statuses', id).subscribe(
          (res: any) => {
            const requestStatus = res;
            resolve(requestStatus);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  async writeToUs(data: any) {
    return new Promise((resolve, reject) => {
      try {
        this.api.postEntityCustomPath(data, 'write-us').subscribe(
          (res: any) => {
            // console.log('res', res)
            resolve(res);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }
}
