<ion-header class="ion-no-border ca-header">
  <ion-toolbar>
    <div class="ca-header__navbar align-center">
      <button class="ca-header__back" slot="start" (click)="dismiss()">
        <i class="far fa-angle-left"></i>
      </button>
      <p class="ca-header__text-center"><strong>{{ title }}</strong></p>
      <button *ngIf="canDelete" class="ca-header__action-right" slot="end" (click)="delete()">
        <i class="fal fa-trash-alt"></i>
      </button>
      <button *ngIf="!canDelete" class="ca-header__action-right" slot="end">
      </button>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-text-center">
  <div *ngIf="imageFormats.includes(format)">
    <img *ngIf="base64String" [src]="'data:' + format + ';base64,' + base64String + ''">
    <img *ngIf="url" [src]="url">
  </div>
  <div *ngIf="'application/pdf' === format">
    <img src="../../assets/Adobe_PDF_icon.svg">
  </div>
  <div *ngIf="videoFormats.includes(format)">
    <video width="100%" height="100%" controls>
      <source *ngIf="base64String" [src]="'data:' + format + ';base64,' + base64String + ''">
      <source *ngIf="url" [src]="url">
      Your device does not support the video tag.
    </video>
  </div>
  <div *ngIf="audioFormats.includes(format)">
    <audio width="100%" height="100%" controls>
      <source *ngIf="base64String" [src]="'data:' + format + ';base64,' + base64String + ''">
      <source *ngIf="url" [src]="url">
      Your browser does not support the audio element.
    </audio>
  </div>
</ion-content>