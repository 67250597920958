import { Component, NgZone } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { LanguageService } from './services/language.service';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { LoadingService } from './services/loading.service';
import { App } from '@capacitor/app';

/**
 * The app
 */
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  /**
   * Flag of loading route status
   */
  isLoadingRoute: boolean = false;
  /**
   * ? To remove?
   */
  loading: any;

  /**
   * Constructor
   * 
   * @param platform 
   * @param splashScreen 
   * @param statusBar 
   * @param languageService 
   * @param router 
   * @param zone 
   * @param loadingService 
   */
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private languageService: LanguageService,
    private router: Router,
    private zone: NgZone,
    private loadingService: LoadingService
  ) {
    this.initializeApp();
  }

  /**
   * App initialization
   */
  initializeApp() {
    this.platform.ready().then(async () => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      await this.loadingService.createLoader(10);
      this.languageService.setInitialAppLanguage();

      if (App) {
        App.addListener('appUrlOpen', (data: any) => {
          this.zone.run(() => {
            // Example url: https://ambiente.app/tabs/tab2
            // slug = /tabs/tab2
            const slug = data.url.split(".app").pop();
            if (slug) {
              this.router.navigateByUrl(slug);
            }
            // If no match, do nothing - let regular routing
            // logic take over
          });
        });
      }
    });
  }

  /**
   * @ignore
   */
  ngOnInit(): void { }
}
