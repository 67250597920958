import { FormsModule } from '@angular/forms';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicStorageModule } from '@ionic/storage';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ErrorHandlerService } from './services/error-handler.service';
import { AuthService } from './services/auth.service';
import { JsonApiService } from './services/jsonapi.service';
import { DataService } from './services/data.service';
import { OptionService } from './services/option.service';
import { RequestService } from './services/request.service';
import { UserService } from './services/user.service';
import { BoatService } from './services/boat.service';
import { CompanyService } from './services/company.service';
import { DocumentService } from './services/document.service';
import { RecoveryService } from './services/recovery.service';
import { CommonModule, LOCATION_INITIALIZED } from '@angular/common';
// import { FilterActivityPipe } from './pipes/filter-activity.pipe';
import { MomentModule } from 'ngx-moment';
import { MediaModalPageModule } from './modals/media-modal/media-modal.module'
import { AssistanceModalPageModule } from './modals/assistance-modal/assistance-modal.module'
import { enterAnimation } from './animations/nav-animations';
import { VideoEditor } from '@ionic-native/video-editor/ngx';
import { MediaCapture } from '@ionic-native/media-capture/ngx';
// import { File } from '@ionic-native/file/ngx';
import { Base64 } from '@ionic-native/base64/ngx';
import { IOSFilePicker } from '@ionic-native/file-picker/ngx';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';

/**
 * Translate loader
 * 
 * @param http 
 * @returns 
 */
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

/**
 * App initialize
 * 
 * @param translate 
 * @param injector 
 * @returns 
 */
export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      translate.setDefaultLang('en');
      const langToSet = 'en'; // translate.getBrowserLang();
      translate.use(langToSet).subscribe(() => {
        console.info(`Successfully initialized '${langToSet}' language.'`);
      }, err => {
        console.error(`Problem with '${langToSet}' language initialization.'`);
      }, () => {
        resolve(null);
      });
    });
  });
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    FormsModule,
    BrowserModule,
    IonicModule.forRoot({
      navAnimation: enterAnimation,
      rippleEffect: false,
      mode: 'ios'
    }),
    AppRoutingModule,
    CommonModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    MomentModule.forRoot({
      relativeTimeThresholdOptions: {
        'm': 59
      }
    }),
    MediaModalPageModule,
    AssistanceModalPageModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    },
    StatusBar,
    SplashScreen,
    FileOpener,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ErrorHandlerService,
    AuthService,
    DataService,
    OptionService,
    RequestService,
    UserService,
    BoatService,
    CompanyService,
    DocumentService,
    RecoveryService,
    JsonApiService,
    VideoEditor,
    MediaCapture,
    Base64,
    IOSFilePicker,
    FileChooser,
    FilePath
    // File
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
