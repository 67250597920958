import { Injectable } from '@angular/core';
import { ErrorHandlerService } from './error-handler.service';
import { JsonApiService } from './jsonapi.service';

@Injectable()
export class CompanyService {

  constructor(
    private errorHandler: ErrorHandlerService,
    private api: JsonApiService
  ) { }

  async getCompany(id: string) {
    return new Promise((resolve, reject) => {
      try {
        this.api.getEntity('companies', id).subscribe(
          (res: any) => {
            const company = res;
            resolve(company);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
            reject(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  createCompany(data: any) {
    return new Promise((resolve, reject) => {
      try {
        this.api.postEntity('companies', data).subscribe(
          (res: any) => {
            const company = res;
            resolve(company);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  updateCompany(id: number, data: any) {
    return new Promise((resolve, reject) => {
      try {
        this.api.updateEntity('companies', id, data).subscribe(
          (res: any) => {
            const company = res;
            resolve(company);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }
}
