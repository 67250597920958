import { Injectable } from '@angular/core';
import { ErrorHandlerService } from './error-handler.service';
import { JsonApiService } from './jsonapi.service';

@Injectable()
export class DocumentService {

  constructor(
    private errorHandler: ErrorHandlerService,
    private api: JsonApiService
  ) { }

  createDocument(data: any) {
    return new Promise((resolve, reject) => {
      try {
        this.api.postEntityCustomPath(data, 'documents/create').subscribe(
          (res: any) => {
            // console.log('res', res)
            const document = res;
            resolve(document);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  getDocument(documentId: number) {
    return new Promise((resolve, reject) => {
      try {
        this.api.customGetRequest('documents/' + documentId + '/show').subscribe(
          (res: any) => {
            // console.log('res', res)
            const document = res;
            resolve(document);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  getDocumentBase64(documentId: number) {
    return new Promise((resolve, reject) => {
      try {
        this.api.customGetRequest('documents/' + documentId + '/show-base64').subscribe(
          (res: any) => {
            // console.log('res', res)
            const document = res;
            resolve(document);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

  deleteDocument(documentId: number) {
    return new Promise((resolve, reject) => {
      try {
        this.api.deleteEntity('documents', documentId).subscribe(
          (res: any) => {
            // console.log('res', res)
            const document = res;
            resolve(document);
          },
          (error: any) => {
            console.warn(error);
            this.errorHandler.manage(error);
          }
        );
      } catch (error) {
        console.error('reject');
        reject(error);
      }
    });
  }

}
