import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

/**
 * Login guard
 */
@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  /**
   * @ignore
   * 
   * @param authService 
   */
  constructor(
    public authService: AuthService
  ) {}

  /**
   * Can activate
   * 
   * @param next 
   * @param state 
   * @returns 
   */
  canActivate(
    next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if(this.authService.userAuthenticated()) {
        return false;
      }
      else {
        return true;
      }
  }
}